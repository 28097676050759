import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import {
	TextField,
	InputLabel,
	MenuItem,
	FormControl,
	Select,
	Dialog,
	FormControlLabel,
	Typography,
	Checkbox,
	RadioGroup,
	Radio,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { Button as CustomButton, Button } from '../Button'
import { ModalYesNo } from './ModalYesNo'
import {
	getValidationMessage,
	textIsValid,
} from '../../services/helper'
import { applyMask } from '../../services/utils'
import { useAppContext } from '../AppContext'
import { testConditions } from '../../services/appService'
import { ArrowBack } from '@mui/icons-material'
import { Roles } from '../../services/client'

export const ModalWizard = ({
	questions,
	modal,
	setModal,
	type,
	save,
	modalData,
	modalTitle,
	finalButtonText = 'Finish',
	errorMsg = '',
	closeOnSave = true,
}) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const { state } = useAppContext()
	const { siteConfig } = state

	const [target, setTarget] = useState('')
	const [modalQuestions, setModalQuestions] = useState([])
	const [answers, setAnswers] = useState({})
	const [defaultAnswers, setDefaultAnswers] = useState({})
	const [inputButtonDisabled, setInputButtonDisabled] = useState(true)
	const [radioButtonDisabled, setRadioButtonDisabled] = useState(true)
	const [targetHistory, setTargetHistory] = useState([])
	const [applicationTitle, setApplicationTitle] = useState('')
	const [
		checkboxGroupButtonDisabled,
		setCheckboxGroupButtonDisabled,
	] = useState(true)
	const [validations, setValidations] = useState({})
	const [modalConfirmation, setModalConfirmation] = useState({
		open: false,
		text: "Are you sure you want to leave? You will lose all data you've entered.",
		yesButtonName: 'Leave',
		noButtonName: 'Cancel',
	})
	const authorizationRef = useRef(null)

	useEffect(() => {
		if (authorizationRef?.current?.scrollHeight <= 254)
			setRadioButtonDisabled(false)
	}, [authorizationRef])

	useEffect(() => {
		if (errorMsg.length) {
			alert(errorMsg)
			const question = questions.find((q) => q.qId === target)
			setValidations((current) => ({
				...current,
				[question.fieldId]: errorMsg,
				isError: true,
			}))
		}
	}, [errorMsg])

	const handleScrollAuthorization = (e) => {
		const bottom =
			e.target.scrollHeight - e.target.scrollTop ===
			e.target.clientHeight
		if (bottom) {
			setRadioButtonDisabled(false)
		}
	}

	const handleSingleAuthCheckbox = (option, question) => {
		setAnswers((current) => ({
			...current,
			[question.fieldId]: !answers[question.fieldId],
		}))
	}

	const handleRadioButtonChange = (question, value) => {
		if (question.fieldId) setInputButtonDisabled(false)
		setAnswers((current) => ({
			...current,
			[question.fieldId]: value,
		}))
	}

	useEffect(() => {
		const mockedQuestions = JSON.parse(JSON.stringify(questions))
		setModalQuestions(mockedQuestions)

		const target = mockedQuestions[0].qId
		!!mockedQuestions && mockedQuestions.length && setTarget(target)
		if (modalTitle) {
			setApplicationTitle(modalTitle)
		} else if (mockedQuestions[0].title) {
			setApplicationTitle(mockedQuestions[0].title)
		}
		if (modalData) {
			setAnswers(modalData)
			setDefaultAnswers(modalData)
		}

		// setTargetHistory(targetHistory => [...targetHistory, target])
		setTargetHistory([target])
	}, [modal])

	useEffect(() => {
		const value = Object.entries(answers).find(
			(item) => item[0] === questions[0].fieldId
		)
		if (value) !!value[1] && setInputButtonDisabled(false)
	}, [modalQuestions])

	const setNextTarget = (target) => {
		const nextQ = modalQuestions.find((q) => q.qId === target)
		if (!nextQ) return saveModal()

		// Move to specified or next target if condition matches (hideIf)
		if (nextQ.hideIf) {
			let conditions
			if (nextQ.hideIf.includes(' and ')) {
				conditions = nextQ.hideIf.split(' and ')
				for (let i = 0; i < conditions.length; i++) {
					if (!testConditions(conditions[i], answers)) return
				}
				return setNextTarget(nextQ.targetIfHidden || nextQ.target)
			} else {
				conditions = nextQ.hideIf.split(' or ')
				for (let i = 0; i < conditions.length; i++) {
					if (testConditions(conditions[i], answers))
						return setNextTarget(nextQ.targetIfHidden || nextQ.target)
				}
			}
		}

		if (!!nextQ.title) setApplicationTitle(replaceVars(nextQ.title))

		if (
			type === 'rules' &&
			answers.taskLOSIdentifier === 'Answer a Question' &&
			target === 'loanPurpose' &&
			!answers.previewYesNo
		) {
			setTarget('previewPicker')
		} else {
			setTarget(target)
		}

		setInputButtonDisabled(
			!nextQ.optional &&
				nextQ.type !== 'range' &&
				!answers[nextQ.fieldId]
		)
		if (nextQ.type === 'checkboxGroup')
			setCheckboxGroupButtonDisabled(
				isCheckboxGroupButtonSDisabled(nextQ)
			)

		setTargetHistory((targetHistory) => [...targetHistory, target])
	}

	const setPrevTarget = () => {
		const target = targetHistory[targetHistory.length - 2]
		const prevQ = modalQuestions.find((q) => q.qId === target)
		setTarget(prevQ.qId)

		if (!!prevQ.title) setApplicationTitle(replaceVars(prevQ.title))

		setInputButtonDisabled(false)
		setCheckboxGroupButtonDisabled(false)

		targetHistory.pop()
	}

	const handleChoiceButtonPress = (event, question, option) => {
		if (
			answers.Filter?.find(
				(item) => item.targetFieldID === question.fieldId
			)
		) {
			let newAnswers = answers
			let newChoice = newAnswers.Filter?.find(
				(item) => item.targetFieldID === question.fieldId
			)
			newChoice.targetFieldValue = option.value
			setAnswers(newAnswers)
		} else {
			setAnswers({ ...answers, [question.fieldId]: option.value })
		}
		setNextTarget(option.target || question.target)
	}

	const handleGroupInputChangeText = (value, question, group) => {
		let newValue = applyMask(value, question.validation)
		setAnswers((answers) => ({
			...answers,
			[question.fieldId]: newValue,
		}))
		handleGroupButtonToggle(newValue, question, group)
	}

	const handleGroupButtonToggle = (value, question, group) => {
		let valid = true
		for (let i in group.items) {
			const item = group.items[i]
			if (item.optional) continue
			let itemValue

			if (item.type === 'row') {
				for (let x in item.items) {
					const child = item.items[x]
					if (child.optional) continue
					itemValue =
						child.fieldId === question.fieldId
							? value
							: answers[child.fieldId] || ''
					if (!textIsValid(itemValue, item.validation)) {
						valid = false
						break
					}
				}
			} else {
				itemValue =
					item.fieldId === question.fieldId
						? value
						: answers[item.fieldId] || ''
				if (!textIsValid(itemValue, item.validation)) {
					valid = false
					break
				}
			}
		}

		setInputButtonDisabled(!valid)
	}

	const handleGroupPickerValueChange = (value, question, group) => {
		if (value.indexOf && value.indexOf('Select a') === 0) value = null
		if (question.type === 'row') {
			const rowQuestion = question.items.find(
				(item) => item.type === 'picker'
			)
			setAnswers((answers) => ({
				...answers,
				[rowQuestion.fieldId]: value,
			}))
		} else {
			setAnswers((answers) => ({
				...answers,
				[question.fieldId]: value,
			}))
		}

		handleGroupButtonToggle(value, question, group)
	}

	const handleInputChangeText = (value, question) => {
		let newValue = applyMask(value, question.validation)

		if (!!question.validation)
			setInputButtonDisabled(
				!textIsValid(newValue, question.validation)
			)
		else
			setInputButtonDisabled(
				!(
					(!question.validation || question.optional) &&
					newValue.length > 1
				)
			)
		setAnswers((answers) => ({
			...answers,
			[question.fieldId]: newValue,
		}))
	}

	const handleInputButtonPress = (event, question) => {
		setNextTarget(question.target)
	}

	const handleInputBlur = (event, question) => {
		const value = Object.entries(answers).find(
			(item) => item[0] === question.fieldId
		)
		let message
		let condition1 =
			question.validation &&
			value !== undefined &&
			!textIsValid(value[1], question.validation)
		let condition2 =
			!question.optional && value !== undefined && value.length < 2
		let condition3 =
			(value === undefined || typeof value === 'undefined') &&
			!question?.optional
		let condition4 =
			!(value === undefined || typeof value === 'undefined') &&
			value[1] === ''
		if (condition1)
			message = getValidationMessage(question.validation)
		else if (condition2 || condition3 || condition4)
			message = getValidationMessage('required')
		setValidations((current) => ({
			...current,
			[question.fieldId]: message,
			isError: !!message,
		}))
	}

	const handleGroupInputBlur = async (event, question) => {
		const value = Object.entries(answers).find(
			(item) => item[0] === question.fieldId
		)
		let message = {}
		message[question.qId] = false
		if (!question.optional) {
			if (
				value === undefined ||
				(!question.validation && value[1].length === 0)
			)
				message[question.qId] = getValidationMessage('required')
			else if (!textIsValid(value[1], question.validation))
				message[question.qId] = getValidationMessage(
					question.validation
				)
		}

		setValidations((validations) => ({
			...validations,
			...message,
			isError: !!message,
		}))
	}

	const handlePickerValueChange = (value, question) => {
		setInputButtonDisabled(!value)
		setAnswers((answers) => ({
			...answers,
			[question.fieldId]: Number(value) ? Number(value) : value,
		}))
	}

	const handleCheckSelect = (question, index) => {
		const checkboxData = question.options
		checkboxData[index].checked = !checkboxData[index].checked
		const checkedData = checkboxData.filter(
			(option) => option.checked === true
		)
		setAnswers((answers) => ({
			...answers,
			[question.fieldId]: checkedData.map((c) => c.name),
		}))
		setCheckboxGroupButtonDisabled(
			isCheckboxGroupButtonSDisabled(question)
		)
	}

	const isCheckboxGroupButtonSDisabled = (question) => {
		let checkboxData = question.options
		const isChecked = checkboxData.some(
			(option) => option.checked === true
		)
		return !isChecked
	}

	const handleCheckboxGroupButtonPress = (event, question) => {
		setNextTarget(question.target)
	}

	const saveModal = () => {
		save(answers)
		if (closeOnSave !== false) yesCloseModals()
	}

	const closeModal = () => {
		if (_.isEqual(answers, defaultAnswers)) {
			setAnswers({})
			setDefaultAnswers({})
			setTargetHistory([])
			setInputButtonDisabled(true)
			setValidations({})
			setModal({ data: null, title: '', visible: false })
		} else {
			setModalConfirmation((modalConfirmation) => ({
				...modalConfirmation,
				open: true,
			}))
		}
	}

	const yesCloseModals = () => {
		setModalConfirmation((modalConfirmation) => ({
			...modalConfirmation,
			open: false,
		}))
		setAnswers({})
		setDefaultAnswers({})
		setTargetHistory([])
		setInputButtonDisabled(true)
		setValidations({})
		setModal({ data: null, title: '', visible: false })
	}

	const replaceVars = (str) => {
		let or
		if (str?.includes('||')) {
			let parts = str.split('||')
			str = `${parts[0].trim()}}}`
			or = parts[1].replace('}}', '').trim()
		}
		let vars = []
		let re = /{{([^}]+)}}/g
		let text

		while ((text = re.exec(str))) {
			vars.push(text[1])
		}

		vars.forEach((v) => {
			str = str.replace(`{{${v}}}`, answers[v] || siteConfig[v] || or)
		})

		return str
	}

	const buttonStyle = fullScreen
		? { width: '90%', marginBottom: 10, marginTop: 20 }
		: { width: 400, marginBottom: 10, marginTop: 20 }

	const displayAnswer = (q) => {
		let value = answers[q.fieldId]
		switch (true) {
			case typeof value === 'boolean':
				return value ? 'Yes' : 'No'
			case q.fieldId.toLowerCase().includes('userrole'):
				return Object.values(Roles)[answers[q.fieldId] - 1]
			default:
				return value
		}
	}

	return (
		<>
			<ModalYesNo
				modalConfirmation={modalConfirmation}
				modalConfirm={yesCloseModals}
				setModalConfirmation={setModalConfirmation}
			/>
			<Dialog
				open={modal}
				onClose={closeModal}
				fullWidth
				aria-labelledby="responsive-dialog-title"
			>
				<div className="flex flex-col items-center justify-center px-6 md:max-w-xl py-8 md:px-10">
					<p className="pb-5 text-xl text-center font-bold">
						{applicationTitle}
					</p>
					{modalQuestions.map((q) => {
						if (q.qId !== target) return null
						let questionText = replaceVars(q.question)
						switch (q.type) {
							case 'information':
								return (
									<div
										key={q.qId}
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
										}}
									>
										<p className="mb-5 mx-10 text-center">
											{questionText}
										</p>
										<Button
											id={`Information${q.qId}AppButton`}
											text={q.buttonText}
											onClick={(e) => handleInputButtonPress(e, q)}
											variant="contained"
											style={buttonStyle}
										/>
									</div>
								)

							// TODO when checkbox will available
							// case "checkboxGroup":

							case 'choice':
								return (
									<div
										key={q.qId}
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
										}}
									>
										{questionText && (
											<p className="text-lg mb-5 mx-10 text-center">
												{questionText}
											</p>
										)}
										{q.options.map((option) => {
											return (
												<Button
													id={`Wizard${q.fieldId}${option.value}ChoiceButton`}
													key={`${q.qId}_${option.value}`}
													text={option.label}
													onClick={(e) =>
														handleChoiceButtonPress(e, q, option)
													}
													variant={
														answers[q.fieldId] === option.value ||
														answers.Filter?.find(
															(item) =>
																item.TargetFieldID === q.fieldId
														)?.TargetFieldValue === option.value
															? 'contained'
															: 'outlined'
													}
													style={
														fullScreen
															? {
																	width: '90%',
																	marginBottom: 10,
																	marginTop: 10,
																}
															: {
																	width: 400,
																	marginBottom: 10,
																	marginTop: 10,
																}
													}
												/>
											)
										})}
									</div>
								)

							case 'text':
								return (
									<div
										key={q.qId}
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
										}}
									>
										{questionText && (
											<p className="text-lg mb-5 mx-10">
												{questionText}
											</p>
										)}
										<div className="flex">
											{q.prefix}
											<TextField
												onChange={(e) =>
													handleInputChangeText(e.target.value, q)
												}
												onBlur={(e) => handleInputBlur(e, q)}
												value={answers[q.fieldId] || ''}
												label={!!answers[q.fieldId] ? null : q.label}
												placeholder={q.placeholder}
												error={validations.isError}
												helperText={validations[q.fieldId]}
												id={`Wizard${q.fieldId}TextInput`}
												inputProps={{ maxLength: q.maxLength }}
												className={
													q.suffix
														? `w-${72 - q.suffix.length} md:w-${96 - q.suffix.length}`
														: `w-72 md:w-96`
												}
												variant="standard"
											/>
											{q.suffix}
										</div>
										<Button
											id={`Wizard${q.fieldId}TextInputContinueButton`}
											text={!q.target ? finalButtonText : 'Continue'}
											onClick={(e) => handleInputButtonPress(e, q)}
											disabled={inputButtonDisabled}
											variant="contained"
											style={buttonStyle}
										/>
									</div>
								)
							case 'textarea':
								return (
									<div
										key={q.qId}
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
										}}
									>
										{questionText && (
											<p className="text-lg mb-5 md:mx-10">
												{questionText}
											</p>
										)}
										<TextField
											id={`Wizard${q.fieldId}Textarea`}
											placeholder={q.placeholder}
											onChange={(e) =>
												handleInputChangeText(e.target.value, q)
											}
											label={!!answers[q.fieldId] ? null : q.label}
											onBlur={(e) => handleInputBlur(e, q)}
											error={validations.isError}
											helperText={validations[q.fieldId]}
											value={answers[q.fieldId] || ''}
											inputProps={{ maxLength: q.maxLength }}
											className="w-72 md:w-96"
											multiline
											rows={4}
										/>
										<Button
											id={`Wizard${q.fieldId}TextAreaContinueButton`}
											text={!q.target ? finalButtonText : 'Continue'}
											onClick={(e) => handleInputButtonPress(e, q)}
											disabled={inputButtonDisabled}
											variant="contained"
											style={buttonStyle}
										/>
									</div>
								)

							case 'picker':
								return (
									<div
										key={q.qId}
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
										}}
									>
										{questionText && (
											<p className="text-lg mb-5 md:mx-10">
												{questionText}
											</p>
										)}
										{q.qId === 'previewPicker' && (
											<p className="text-lg mb-5 md:mx-10">
												{answers.questionName}
											</p>
										)}
										<FormControl
											key={`Wizard${q.fieldId}Picker`}
											variant="standard"
										>
											<InputLabel>{q.default}</InputLabel>
											<Select
												value={
													answers[q.fieldId] === 0
														? 0
														: answers[q.fieldId]
															? answers[q.fieldId]
															: ''
												}
												onChange={(v) =>
													handlePickerValueChange(v.target.value, q)
												}
												label={q.default}
												className="w-72 md:w-96 align-center"
											>
												<MenuItem key="default" value="">
													{q.default}
												</MenuItem>
												{q.options.map((option, index) => (
													<MenuItem key={index} value={option.value}>
														{option.label}
													</MenuItem>
												))}
											</Select>
										</FormControl>
										<Button
											id={`Wizard${q.fieldId}PickerContinueButton`}
											text={!q.target ? finalButtonText : 'Continue'}
											onClick={(e) => handleInputButtonPress(e, q)}
											disabled={inputButtonDisabled}
											variant="contained"
											style={buttonStyle}
										/>
									</div>
								)

							case 'group':
								return (
									<div
										key={`Group${q.qId}ModalWizard`}
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
										}}
									>
										{q?.items &&
											q.items.map((item) => {
												let questionText = replaceVars(item.question)
												switch (item.type) {
													case 'text':
														return (
															<div
																className="mt-2"
																key={item.qId}
																style={
																	q.qId !== target
																		? { display: 'none' }
																		: {}
																}
															>
																<TextField
																	onChange={(e) =>
																		handleGroupInputChangeText(
																			e.target.value,
																			item,
																			q
																		)
																	}
																	onBlur={(e) =>
																		handleGroupInputBlur(e, item, q)
																	}
																	value={answers[item.fieldId] || ''}
																	label={item.label || item.question}
																	placeholder={item.placeholder}
																	error={validations.isError}
																	helperText={
																		validations[item.fieldId]
																	}
																	id={`Wizard${item.fieldId}GroupTextInput`}
																	inputProps={{
																		maxLength: q.maxLength,
																	}}
																	className="w-52 md:w-72"
																	variant="standard"
																/>
															</div>
														)
													case 'textarea':
														return (
															<div key={item.qId}>
																{
																	<p className="text-lg mb-5 md:mx-10">
																		{typeof questionText !==
																		'undefined'
																			? questionText
																			: item.question}
																	</p>
																}
																<TextField
																	id={`Wizard${item.fieldId}GroupTextarea`}
																	placeholder={item.placeholder}
																	onChange={(e) =>
																		handleInputChangeText(
																			e.target.value,
																			item
																		)
																	}
																	label={
																		!!answers[item.fieldId]
																			? null
																			: item.label
																	}
																	onBlur={(e) =>
																		handleGroupInputBlur(e, item, q)
																	}
																	error={validations.isError}
																	helperText={
																		validations[item.fieldId]
																	}
																	value={answers[item.fieldId] || ''}
																	inputProps={{
																		maxLength: item.maxLength,
																	}}
																	className="w-52 md:w-72"
																	multiline
																	rows={4}
																/>
															</div>
														)
													case 'picker':
														return (
															<div key={item.qId}>
																{item.qId === 'previewPicker' && (
																	<p className="text-lg mb-5 md:mx-10">
																		{answers.questionName}
																	</p>
																)}
																<FormControl
																	key={`Wizard${item.fieldId}GroupPicker`}
																	variant="standard"
																	sx={
																		fullScreen
																			? { width: 210 }
																			: { width: 290 }
																	}
																>
																	<InputLabel>
																		{item.default || questionText}
																	</InputLabel>
																	<Select
																		value={
																			answers[item.fieldId] || ''
																		}
																		onChange={(v) =>
																			handleGroupPickerValueChange(
																				v.target.value,
																				item,
																				q
																			)
																		}
																		label={item.default}
																	>
																		<MenuItem key="default" value="">
																			{item.default}
																		</MenuItem>
																		{item.options.map(
																			(option, index) => (
																				<MenuItem
																					key={index}
																					value={option.value}
																				>
																					{option.label}
																				</MenuItem>
																			)
																		)}
																	</Select>
																</FormControl>
															</div>
														)
													default:
														return null
												}
											})}
										<div className="mt-4">
											<Button
												className="mt-5"
												id={`Wizard${q.fieldId}GroupContinueButton`}
												text={
													!q.target ? finalButtonText : 'Continue'
												}
												onClick={(e) => handleInputButtonPress(e, q)}
												style={buttonStyle}
											/>
										</div>
									</div>
								)

							case 'authorization':
								return (
									<div
										key={`${q.qId}-authorization`}
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
										}}
									>
										<div
											ref={authorizationRef}
											className="h-64 overflow-auto border border-zinc-300 rounded-md px-3 py-3 mb-2"
											onScroll={handleScrollAuthorization}
										>
											<p className="text-sm text-slate-400 pointer-events-none select-none">
												{questionText}
											</p>
										</div>
										{q.options?.length === 1 ? (
											q.options.map((option, index) => (
												<FormControlLabel
													label={
														<Typography className="dark:text-white">
															{radioButtonDisabled && false
																? 'Scroll to bottom to enable checkbox'
																: option.text}
														</Typography>
													}
													key={`${q.qId}-${index}-authorization`}
													disabled={radioButtonDisabled && false}
													control={
														<Checkbox
															id={`${q.qId}${option.value ? 'True' : 'False'}AuthorizationCheckbox`}
															checked={answers[q.fieldId] === true}
															onChange={() =>
																handleSingleAuthCheckbox(option, q)
															}
															inputProps={{
																'aria-label': 'controlled',
															}}
														/>
													}
												/>
											))
										) : (
											<FormControl>
												<RadioGroup
													name="radio-buttons-group"
													className="mt-2"
												>
													{q.options?.map((option, index) => (
														<FormControlLabel
															value={option.value}
															control={
																<Radio
																	size="small"
																	onChange={(e) =>
																		handleRadioButtonChange(
																			q,
																			e.target.value
																		)
																	}
																/>
															}
															label={option.text}
															key={index}
															id={`${q.qId}${option.value}AuthorizationRadioButton`}
														/>
													))}
												</RadioGroup>
											</FormControl>
										)}
										<CustomButton
											id={`${q.qId}AppButton`}
											text={q.target ? 'Continue' : 'Finish'}
											onClick={(e) => handleInputButtonPress(e, q)}
											disabled={answers[q.fieldId] !== true}
											variant="contained"
											style={buttonStyle}
										/>
									</div>
								)
							case 'review':
								return (
									<div
										key={q.qId}
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
										}}
									>
										<p className="mb-5 mx-10 text-center">
											{questionText}
										</p>
										{questions.map(
											(q) =>
												!!answers[q.fieldId] && (
													<div
														className={'flex w-full justify-between'}
													>
														<div className={`w-1/2`}>{q.name}:</div>
														<div className={`w-1/2 font-bold`}>
															{displayAnswer(q)}
														</div>
													</div>
												)
										)}
										<Button
											id={`Information${q.qId}AppButton`}
											text={q.buttonText}
											onClick={(e) => handleInputButtonPress(e, q)}
											variant="contained"
											style={{ ...buttonStyle, marginTop: 40 }}
										/>
									</div>
								)
							default:
								return null
						}
					})}
					{targetHistory.length > 1 && (
						<div
							className={`justify-self-start w-full mt-10 cursor-pointer`}
							onClick={setPrevTarget}
						>
							<ArrowBack /> Back
						</div>
					)}
				</div>
			</Dialog>
		</>
	)
}
