import React, { useEffect, useState } from 'react'
import { useVenti } from 'venti'
import Page from '../../../components/Page'
import DataTable from '../../../components/DataTable'
import { useAppContext } from '../../../components/AppContext'
import { useAppContextActions } from '../../../components/AppContext/AppHooks'
import { getTheme } from '../../../config'
import { ModalYesNo } from '../../../components/modals/ModalYesNo'
import {
	deleteRequestQueue,
	getRequestsQueue,
	runQueuedTask,
} from '../../../services/client'
import { formatDate, getErrorMessage } from '../../../services/helper'
import { useAlert } from '../../../hooks'
import { ModalRemoveRestoreRecordConfirm } from '../../../components/modals/ModalRemoveRestoreRecordConfirm'
import { ModalRawData } from '../../../components/modals/ModalRawData'

const theme = getTheme()

export default function AdminRequestQueue() {
	const { alert } = useAlert()
	const { state } = useAppContext()
	const { logOut } = useAppContextActions()
	const { user } = state
	const ventiState = useVenti()

	const [fetching, setFetching] = useState(true)
	const [data, setData] = useState([])
	const [filteredData, setFilteredData] = useState([])
	const [activeRow, setActiveRow] = useState()
	const [searchText, setSearchText] = useState('')
	const [modalConfirmation, setModalConfirmation] = useState({
		open: false,
		text: 'Are you sure you want to send this request?',
		leavePage: false,
		canBeSaved: false,
		isSave: false,
		event: null,
	})
	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const [rowToRemove, setRowToRemove] = useState()
	const [showRowDataModal, setShowRowDataModal] = useState(false)
	const columns = [
		{
			name: 'Endpoint',
			selector: (row) => row.endpoint,
			sortable: true,
		},
		{
			name: 'Error Message',
			selector: (row) => row.errorMessage,
			sortable: true,
		},
		{
			name: 'User',
			selector: (row) => row.userEmail,
			sortable: true,
		},
		{
			name: 'Created On',
			selector: (row) => formatDate(row.createdAt, false),
			sortable: true,
		},
	]

	const actionItems = [
		{
			name: 'View Details',
			onClick: (e, row) => handleViewDetailsModal(e, row),
			hideIf: (row) => row.deletedAt !== null,
		},
		{
			name: 'Send',
			onClick: (e, row) => handleOnUse(row),
			hideIf: (row) => row.deletedAt !== null,
		},
		{
			name: 'Remove',
			onClick: (e, row) => showRemoveModal(row),
			hideIf: (row) => row.deletedAt !== null,
		},
	]

	useEffect(() => {
		;(async () => {
			if (!user.isLoggedIn) await logOut()
			else await loadRequestQueue()
		})()
	}, [user])

	useEffect(() => {
		if (searchText !== '') {
			let newFilteredData = !searchText
				? data
				: data.filter(
						(d) =>
							d.userEmail
								?.toLowerCase()
								.includes(searchText.toLowerCase()) ||
							d.endpoint
								?.toLowerCase()
								.includes(searchText.toLowerCase()) ||
							d.errorMessage
								?.toLowerCase()
								.includes(searchText.toLowerCase()) ||
							d.status
								?.toLowerCase()
								.toString()
								.includes(searchText.toLowerCase())
					)
			setFilteredData(newFilteredData)
		} else {
			setFilteredData(data)
		}
	}, [data, searchText])

	const loadRequestQueue = async () => {
		try {
			setFetching(true)
			const requestsData = await getRequestsQueue()
			const requests = []
			requestsData.forEach((item) => {
				requests.push({ ...item, ID: item.id })
			})
			setData(requests)
			setFetching(false)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		}
	}

	const handleViewDetailsModal = (e, row) => {
		setActiveRow(row)
		setShowRowDataModal(true)
	}

	const handleCloseDetailsModal = (e, row) => {
		setActiveRow(null)
		setShowRowDataModal(false)
	}

	const handleOnUse = (row) => {
		setActiveRow(row)
		setModalConfirmation((current) => ({ ...current, open: true }))
	}

	const sendTask = async () => {
		try {
			setModalConfirmation((current) => ({ ...current, open: false }))
			await runQueuedTask(activeRow.id)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message ||
					'There was a problem retrying the task. Please contact your administrator.'
			)
		}
	}

	const showRemoveModal = (row) => {
		setRemoveModalVisible(true)
		setRowToRemove(row)
	}

	const removeRequest = async () => {
		setRemoveModalVisible(false)

		try {
			await deleteRequestQueue(rowToRemove.id)
			await loadRequestQueue()
			alert(`Queued request successfully removed`)
			setRowToRemove(null)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		}
	}

	const onSearchChange = (e) => {
		setSearchText(e.target.value)
	}

	return (
		<Page title="Loan Officers" isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 h-full overflow-auto">
				<ModalYesNo
					yesButtonName="Send"
					noButtonName="Cancel"
					modalConfirmation={modalConfirmation}
					setModalConfirmation={setModalConfirmation}
					modalConfirm={sendTask}
				/>
				<ModalRemoveRestoreRecordConfirm
					removeModalVisible={removeModalVisible}
					setRemoveModalVisible={setRemoveModalVisible}
					remove={removeRequest}
					row={rowToRemove}
				/>
				{showRowDataModal && (
					<ModalRawData
						data={activeRow}
						visible={Object.entries(activeRow).length !== 0}
						closeModal={handleCloseDetailsModal}
					/>
				)}
				<DataTable
					data={filteredData}
					columns={columns}
					defaultSortAsc={false}
					defaultSortFieldId="createdAt"
					pagination={true}
					progressPending={fetching}
					title={'Queued Requests'}
					onRefreshClick={loadRequestQueue}
					keyField="id"
					fixedHeader={true}
					actionItems={actionItems}
					onSearchChange={onSearchChange}
				/>
			</div>
		</Page>
	)
}
