import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isDarkMode } from '../services/helper'

export const Footer = ({
	legalLinks,
	socialLinks,
	siteConfig = [],
}) => (
	<div className="px-4 pb-4">
		<div className="flex flex-col sm:flex-row sm:justify-between mt-12 mb-5 ">
			<div className="mb-4 sm:mb-0">
				{siteConfig && siteConfig.logoUrl && (
					<img
						className="w-80 mb-8"
						src={
							isDarkMode() && siteConfig?.darkModeLogoUrl
								? siteConfig.darkModeLogoUrl
								: siteConfig.logoUrl
						}
						alt="Logo"
					/>
				)}
				{!!siteConfig?.nmlsid && (
					<p className="text-base font-rubik mb-2.5 text-gray-600">
						NMLS #: {siteConfig.nmlsid}
					</p>
				)}
				<p className="text-base font-rubik mb-2.5 text-gray-600">
					{(siteConfig && siteConfig.address) || ''}
				</p>
				{siteConfig && !!siteConfig.address2 && (
					<p className="text-base font-rubik mb-2.5 text-gray-600">
						{siteConfig.address2}
					</p>
				)}
				<p className="text-base font-rubik mb-2.5 text-gray-600">
					{siteConfig && siteConfig.city},{' '}
					{siteConfig && siteConfig.state}{' '}
					{siteConfig && siteConfig.zip}
				</p>
			</div>
			<div className="flex">
				<div className="mr-6">
					<p className="text-lg font-rubik mb-6 text-black font-semibold dark:text-white">
						Legal
					</p>
					<div>
						{legalLinks &&
							legalLinks.map((link, index) => (
								<a
									target="_blank"
									rel="noreferrer"
									href={link.link}
									className="flex mb-4 hover:underline"
									key={`${link.linkItem + index}`}
								>
									<p className="text-base font-rubik text-gray-600">
										{link.linkItem}
									</p>
								</a>
							))}
					</div>
				</div>
				<div>
					<p className="text-lg font-rubik mb-6 text-black font-semibold dark:text-white">
						Social Media
					</p>
					<div>
						{socialLinks &&
							socialLinks.map((link, index) => (
								<a
									target="_blank"
									rel="noreferrer"
									href={link.link}
									className="flex items-center mb-4 hover:underline"
									key={`${link.linkItem + index}`}
								>
									<div className="flex items-center w-6 h-5">
										<FontAwesomeIcon
											icon={link.icon}
											key={`${link.icon + index}`}
											className="dark:text-slate-500"
										/>
									</div>
									<p className="text-base font-rubik text-gray-600">
										{link.linkItem}
									</p>
								</a>
							))}
					</div>
				</div>
			</div>
		</div>
		<div>
			<p className="text-xs font-rubik font-light text-gray-500 mb-5">
				{siteConfig && siteConfig.footerDisclaimerText1}
			</p>
			<p className="text-xs font-rubik font-light text-gray-500">
				{siteConfig && siteConfig.footerDisclaimerText2}
			</p>
		</div>
	</div>
)
