import DataTable from '../../../components/DataTable'
import {
	FormControl,
	InputLabel,
	Link,
	MenuItem,
	Select,
} from '@mui/material'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { ModalRemoveRestoreRecordConfirm } from '../../../components/modals/ModalRemoveRestoreRecordConfirm'
import {
	deletePartner,
	getMyRelationships,
} from '../../../services/client'
import { cloneDeep, omit } from 'lodash'
import { useAlert, useWindowSize } from '../../../hooks'
import { useNavigate } from 'react-router-dom'
import { useVenti } from 'venti'
import { useAppContext } from '../../../components/AppContext'
import { applyMask } from '../../../services/utils'
import { formatDate, getErrorMessage } from '../../../services/helper'
import { navigationLinking } from '../../../services/navigation'
import { getTheme } from '../../../config'

const theme = getTheme()

const Roles = {
	realtor: 'Realtor', // 4
	settlementAgent: 'SettlementAgent',
}

export default function Current() {
	const breakpoint = 768
	const [width] = useWindowSize()
	const navigate = useNavigate()
	const { alert } = useAlert()
	const ventiState = useVenti()
	const { state } = useAppContext()
	const { user } = state

	const [fetching, setFetching] = useState(true)
	const [data, setData] = useState([])
	const [filteredData, setFilteredData] = useState([])
	const [role, setRole] = useState('all')
	const [searchText, setSearchText] = useState('')
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)
	const [selectedPartner, setSelectedPartner] = useState(null)

	const columns = [
		{
			name: 'Company',
			selector: (row) =>
				row.siteConfigurations.map((site) => (
					<div>{site.companyName}</div>
				)),
			sortable: true,
		},
		{
			name: 'First Name',
			selector: (row) => row.firstName,
			sortable: true,
		},
		{
			name: 'Last Name',
			selector: (row) => row.lastName,
			sortable: true,
		},
		{
			name: 'Phone',
			selector: (row) => applyMask(row.phone, 'phone'),
			hide: breakpoint,
		},
		{
			name: 'Email',
			selector: (row) => row.email,
			sortable: true,
			hide: breakpoint,
		},
		{
			name: 'Site Url',
			selector: (row) =>
				row.siteConfigurations.map((site) => (
					<Link href={`https://${site.url}`} target="_blank">
						{site.url}
					</Link>
				)),
			sortable: true,
			hide: breakpoint,
		},
		{
			name: 'Role',
			selector: (row) => row.role,
			sortable: true,
			hide: breakpoint,
		},
		{
			name: 'Created',
			selector: (row) => formatDate(row.createdAt, false),
			sortable: true,
			hide: breakpoint,
		},
	]

	const loadPartners = useCallback(async () => {
		try {
			setFetching(true)

			let partners = await getMyRelationships()

			setData(partners)
			setFilteredData(partners.filter((r) => r.Role === role))
			setFetching(false)
		} catch (err) {
			alert(getErrorMessage(err), { severity: 'error' })
			setFetching(false)
			ventiState.set(
				theme.storageKeys.errorMessage,
				'There was an error fetching the data'
			)
		}
	}, [alert, role])

	useEffect(() => {
		if (!user?.isLoggedIn) {
			navigate(`/${navigationLinking.SignIn}`)
		} else {
			;(async () => {
				await loadPartners()
			})()
		}
	}, [loadPartners, navigate, user])

	const onSearchChange = (e) => {
		setSearchText(e.target.value)
	}

	useEffect(() => {
		if (searchText !== '') {
			const newFilteredData = data.filter(
				(d) =>
					d.firstName
						?.toLowerCase()
						.toString()
						.includes(searchText.toLowerCase()) ||
					d.lastName
						?.toLowerCase()
						.toString()
						.includes(searchText.toLowerCase()) ||
					d.email
						?.toLowerCase()
						.toString()
						.includes(searchText.toLowerCase()) ||
					d.siteConfigurations[0]?.url
						?.toLowerCase()
						.toString()
						.includes(searchText.toLowerCase())
			)
			setFilteredData(
				role === 'all'
					? newFilteredData
					: newFilteredData.filter((d) => d.Role === role)
			)
		} else {
			setFilteredData(
				role === 'all' ? data : data.filter((d) => d.Role === role)
			)
		}
	}, [data, searchText, role])

	const exportData = useMemo(() => {
		const d = []
		filteredData.forEach((item) => {
			d.push({ ...omit(item, 'Data'), ...item.Data })
		})
		return d
	}, [filteredData])

	const handleRoleChange = (e) => {
		setRole(e.target.value)
	}

	const onDeleteClick = async (row) => {
		setSelectedPartner(row)
		setDeleteModalOpen(true)
	}

	const handleDeleteConfirm = async () => {
		try {
			console.log(selectedPartner)
			await deletePartner(selectedPartner.id)
			let newPartners = cloneDeep(data)
			const partnerIndex = newPartners.findIndex(
				(l) => l.id === selectedPartner.id
			)
			newPartners.splice(partnerIndex, 1)
			setData(newPartners)
		} catch (e) {
			const errorMessage = 'There was a problem deleting the partner'
			alert(errorMessage, { severity: 'error' })
			ventiState.set(theme.storageKeys.errorMessage, errorMessage)
		} finally {
			setSelectedPartner(null)
			setDeleteModalOpen(false)
		}
	}

	return (
		<>
			<ModalRemoveRestoreRecordConfirm
				removeModalVisible={deleteModalOpen}
				setRemoveModalVisible={setDeleteModalOpen}
				remove={handleDeleteConfirm}
				row={selectedPartner}
			/>
			<div className="pl-5 pr-5 pb-10 h-full overflow-auto">
				<DataTable
					data={filteredData}
					exportData={exportData}
					columns={columns}
					defaultSortAsc={false}
					defaultSortFieldId="CreatedAt"
					pagination={true}
					progressPending={fetching}
					title="Partners"
					onRefreshClick={loadPartners}
					keyField="ID"
					onRowClicked={() => false}
					onSearchChange={onSearchChange}
					exportEnabled={true}
					expandableRows={width < breakpoint}
					fixedHeader={true}
					searchFilters={
						<FormControl
							id="filterRole"
							variant="standard"
							sx={[width < 768 ? { width: 256 } : { width: 300 }]}
						>
							<InputLabel>Role</InputLabel>
							<Select value={role} onChange={handleRoleChange}>
								<MenuItem value="all">All</MenuItem>
								{Object.keys(Roles).map((key) => (
									<MenuItem value={key} key={key}>
										{Roles[key]}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					}
					// actionItems={[
					//   {
					//     name: 'Deactivate',
					//     onClick: (e, row) => onDeleteClick(row)
					//   }
					// ]}
				/>
			</div>
		</>
	)
}
