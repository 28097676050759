import React, { useEffect, useMemo, useState } from 'react'
import { useVenti } from 'venti'
import clsx from 'clsx'
import { cloneDeep } from 'lodash'
import SwipeableViews from 'react-swipeable-views'
import { useTheme } from '@mui/material/styles'
import {
	Tabs,
	Tab,
	Card,
	CardContent,
	FormControlLabel,
	Button,
	FormControl,
	TextField,
	Switch,
	Autocomplete,
	Typography,
} from '@mui/material'
import Page from '../../../components/Page'
import { useAppContext } from '../../../components/AppContext'
import { getTheme } from '../../../config'
import {
	getNotificationTemplate,
	saveNotificationTemplate,
	sendTestNotification,
	getLoanOfficers,
	getSiteConfigurationByUrl,
	saveNotificationTemplateVersion,
	deleteNotificationTemplateVersion,
} from '../../../services/client'
import { navigationLinking } from '../../../services/navigation'
import { a11yProps, getErrorMessage } from '../../../services/helper'
import sampleLoanData from '../../../services/sampleLoanData.json'
import { useAlert } from '../../../hooks'
import MailMergeSelect from '../../../components/MailMergeSelect'
import { LoadingBtn } from '../../../components/Button'
import FormLabel from '@mui/material/FormLabel'
import { useMixpanel } from '../../../hooks/useMixpanel'
import { eventTypes } from '../../../services/constants'
import CodeEditor from '../../../components/CodeEditor'
import HandlebarsPreview from '../../../components/HandlebarsPreview'
import useHTMLTemplatePreview from '../../../hooks/useHTMLTemplatePreview'
import {
	useNavigate,
	useParams,
	generatePath,
} from 'react-router-dom'
import ModalVersionList from '../../../components/modals/ModalVersionList'
import { ModalYesNo } from '../../../components/modals/ModalYesNo'
import ModalNewVersion from '../../../components/modals/ModalNewVersion'
import StatusButton from '../../../components/versions/StatusButton'
import StatusIcon from '../../../components/versions/StatusIcon'
import VersionSelector from '../../../components/versions/VersionSelector'
import ActiveVersionAlert from '../../../components/versions/ActiveVersionAlert'

const theme = getTheme()

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <div className="mt-5">{children}</div>}
		</div>
	)
}

export default function AdminNotificationTemplateEdit(props) {
	const DRAFT_STATUS = 'Draft'
	const PUBLISHED_STATUS = 'Published'
	const { id } = useParams()
	const { alert } = useAlert()
	const navigate = useNavigate()
	const ventiState = useVenti()
	const { state } = useAppContext()
	const themeMUI = useTheme()
	const mixpanel = useMixpanel()
	const { siteConfig, user } = state
	const [tab, setTab] = useState(0)
	const [saving, setSaving] = useState(false)
	const [loanOfficer, setLoanOfficer] = useState(null)
	const [loanOfficers, setLoanOfficers] = useState([])
	const [selectedSiteConfig, setSelectedSiteConfig] = useState({})
	const [hidePreview, setHidePreview] = useState(false)
	const [saveType, setSaveType] = useState('update')
	const [fetching, setFetching] = useState(true)
	const [selectedVersion, setSelectedVersion] = useState(null)
	const [versionsModalOpen, setVersionsModalOpen] = useState(false)
	const [selectedVersionActive, setSelectedVersionActive] =
		useState(false)
	const [newVersionModalOpen, setNewVersionModalOpen] =
		useState(false)
	const [statusModal, setStatusModal] = useState({
		open: false,
		text: '',
		isSave: false,
	})

	const { getPreviewData } = useHTMLTemplatePreview()

	const [template, setTemplate] = useState({
		id: '',
		name: '',
		subject: '',
		htmlBody: '',
		plainBody: '',
		textBody: '',
		pushNotificationBody: '',
		textEnabled: false,
		emailEnabled: true,
		pushNotificationEnabled: true,
		description: '',
		useDefaultHeaderAndFooter: false,
		createdAt: new Date(),
		updatedAt: new Date(),
		versions: [],
	})

	const currentActiveVersion = useMemo(() => {
		return template?.versions.find((v) => v.isActive)
	}, [template])

	useEffect(() => {
		if (id !== 'new') {
			setFetching(true)
			getNotificationTemplate(id)
				.then((data) => {
					const activeVersion = data.versions.find((v) => v.isActive)
					setSelectedVersion(activeVersion)
					setTemplate(data)
				})
				.catch((e) => {
					alert(getErrorMessage(e), { severity: 'error' })
					ventiState.set(
						theme.storageKeys.errorMessage,
						e?.data?.message || theme.api_messages.server_error
					)
				})
				.finally(() => {
					setFetching(false)
				})
		} else {
			setFetching(false)
		}

		getLoanOfficers().then((items) => {
			const loanOfficersData = []
			items.forEach((item) => {
				item?.siteConfigurations.forEach((site) => {
					loanOfficersData.push({
						...item,
						label: `${item.firstName} ${item.lastName}`,
						url: site.url,
						siteConfigurationId: site.id,
					})
				})
			})
			setLoanOfficers(loanOfficersData)
		})
	}, [id])

	useEffect(() => {
		if (selectedVersion) {
			setTemplate((current) => ({
				...current,
				htmlBody: selectedVersion.htmlBody || '',
			}))
		} else {
			setSelectedVersion(currentActiveVersion)
		}
	}, [selectedVersion, currentActiveVersion, template.versions])

	const canSubmit =
		template?.name.length > 0 && template?.htmlBody.length > 0

	const handleLoanOfficerSelect = async (event, value) => {
		if (value)
			setSelectedSiteConfig(
				await getSiteConfigurationByUrl(
					value.siteConfigurations[0].url
				)
			)
		else setSelectedSiteConfig({})
		setLoanOfficer(value)
		mixpanel.trackEvent(
			eventTypes.PREVIEW_AFTER_LOAN_OFFICER_SELECTED
		)
	}

	const handleStatusClick = () => {
		const statusChangeText = `Are you sure you want to ${
			template.status === 'Draft' ? 'publish' : 'unpublish'
		} this document template?`

		setStatusModal({
			open: true,
			text: statusChangeText,
			isSave: false,
		})
	}

	const handleNewVersionDialogClose = () => {
		setNewVersionModalOpen(false)
	}

	const handleNewVersionClick = () => {
		setNewVersionModalOpen(true)
	}

	const handleViewVersionsClick = () => {
		setVersionsModalOpen(true)
	}

	const handleViewVersionsClose = () => {
		setVersionsModalOpen(false)
	}

	const handleVersionChange = (e) => {
		const version = template.versions.find(
			(v) => v.id === e.target.value
		)
		setSelectedVersion(version)
		setSelectedVersionActive(false)
	}

	const handleVersionClick = (version) => {
		setSelectedVersion(version)
		setVersionsModalOpen(false)
	}

	const closeStatusModal = () => {
		setStatusModal({
			open: false,
			text: '',
			isSave: false,
		})
	}

	const handleVersionDelete = async (version) => {
		try {
			await deleteNotificationTemplateVersion({
				templateId: template.id,
				versionId: version.id,
			})
			setVersionsModalOpen(false)
			const updatedTemplate = cloneDeep(template)
			updatedTemplate.versions = updatedTemplate.versions.filter(
				(v) => v.id !== version.id
			)
			setTemplate(updatedTemplate)
			alert(`Version ${version.name} successfully deleted`)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		}
	}

	const handleStatusConfirm = async () => {
		try {
			const requestData = {
				...template,
				status:
					template.status === DRAFT_STATUS
						? PUBLISHED_STATUS
						: DRAFT_STATUS,
			}
			await saveNotificationTemplate(requestData)
			setTemplate(requestData)
			closeStatusModal()
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		}
	}

	const handleNewVersionSubmit = async (data) => {
		try {
			setSaving(true)
			const version = await saveNotificationTemplateVersion({
				templateId: template?.id,
				data: {
					htmlBody: template?.htmlBody,
					...data,
				},
			})

			const updatedTemplate = cloneDeep(template)
			if (data.isActive) {
				const prevActiveVersionIndex =
					updatedTemplate.versions.findIndex((v) => v.isActive)
				updatedTemplate.versions[prevActiveVersionIndex].isActive =
					false
			}
			updatedTemplate.versions.unshift(version)
			setTemplate(updatedTemplate)
			setSelectedVersion(version)
			setSelectedVersionActive(false)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		} finally {
			setSaving(false)
			setNewVersionModalOpen(false)
		}
	}

	const saveTemplate = async () => {
		try {
			setSaving(true)
			const { id, name } = template

			if (template.isDefault) {
				delete template.id
				delete template.isDefault
				template.accountID = user.accountID
			}

			const result = await saveNotificationTemplate(template)

			const eventType = template.id
				? eventTypes.NOTIFICATION_TEMPLATE_UPDATED
				: eventTypes.NOTIFICATION_TEMPLATE_CREATED

			mixpanel.trackEvent(eventType, {
				name,
				...(id && { id }),
			})

			const data = await getNotificationTemplate(result.id)
			setTemplate(data)
			const activeVersion = data.versions.find((v) => v.isActive)
			setSelectedVersion(activeVersion)
			alert(
				`Notification Template ${template.name} successfully ${template.id ? 'updated' : 'created'}`
			)
			if (!template.id) {
				navigate(
					generatePath(
						`/${navigationLinking.AdminNotificationTemplateEdit}`,
						{ id: result.id }
					)
				)
			}
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		} finally {
			setSaving(false)
		}
	}

	const sendTest = () => {
		const { id, name } = template
		sendTestNotification({
			templateName: template.name,
			toAddress: loanOfficer?.email || '',
			loanData: sampleLoanData,
			emailSubjectLine: 'Test Notification',
			losUserID: loanOfficer?.losUserID || '',
			siteConfigurationId:
				loanOfficer?.siteConfigurations?.[0].id || siteConfig?.id,
		})
			.then(() => {
				mixpanel.trackEvent(eventTypes.SEND_TEST_NOTIFICATION, {
					name,
					id,
				})
				alert(`Notification ${template.name} successfully sent`)
				setLoanOfficer(null)
			})
			.catch((e) => {
				alert(getErrorMessage(e), { severity: 'error' })
				ventiState.set(
					theme.storageKeys.errorMessage,
					e?.data?.message || theme.api_messages.server_error
				)
			})
	}

	const handleTabChange = (event, value) => {
		setTab(value)
	}

	const handleChangeIndex = (index) => {
		setTab(index)
	}

	const previewData = useMemo(
		() => getPreviewData(template),
		[template]
	)

	let saveButtonText = 'Create'
	if (template?.id) {
		if (template.isDefault) {
			saveButtonText = 'Override Default'
		} else if (saveType !== 'update') {
			saveButtonText = 'Create Version'
		} else {
			saveButtonText = 'Update Template'
		}
	}

	return (
		<Page isFullWidth={true} title="Edit">
			<ModalYesNo
				modalConfirmation={statusModal}
				modalConfirm={handleStatusConfirm}
				setModalConfirmation={setStatusModal}
			/>
			<ModalNewVersion
				open={newVersionModalOpen}
				onDialogClose={handleNewVersionDialogClose}
				onSubmit={handleNewVersionSubmit}
			/>
			<div className="pl-5 pr-5 pb-20 pt-5 h-screen overflow-auto">
				<p className="text-xl md:text-2xl font-rubik font-bold mr-4 mb-10 dark:text-white">
					Notification Templates -{' '}
					{id === 'new'
						? 'Create New Template'
						: `${template.description}`}
				</p>
				<div className="flex flex-wrap justify-end my-5">
					{!template?.isDefault && id !== 'new' && (
						<div className="mr-3">
							<LoadingBtn
								disabled={!canSubmit || saving || 0 !== tab}
								text="Save as new version"
								onClick={handleNewVersionClick}
								fullWidth={false}
								variant="outlined"
							/>
						</div>
					)}
					<LoadingBtn
						id={`AdminCorporateEditSaveButton`}
						disabled={!canSubmit || saving || tab === 3}
						loading={saving}
						text={saveButtonText}
						onClick={saveTemplate}
						fullWidth={false}
					/>
				</div>
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					value={tab}
					onChange={handleTabChange}
					aria-label="Tabs"
				>
					<Tab label="Edit" {...a11yProps(0)} />
					<Tab label="Preview" {...a11yProps(1)} />
					<Tab label="Test" {...a11yProps(2)} />
				</Tabs>
				<SwipeableViews
					axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={tab}
					onChangeIndex={handleChangeIndex}
				>
					<TabPanel value={tab} index={0} dir={themeMUI.direction}>
						<div className="flex w-full">
							<div className="w-full">
								<MailMergeSelect combinedOptions className={'flex'} />
							</div>
							<FormControl className="w-1/3 flex">
								{!template.isDefault && (
									<div className="flex flex-row items-center justify-end my-2">
										<StatusIcon status={template.status} />
										{template.id && (
											<StatusButton
												status={template.status}
												onClick={handleStatusClick}
											>
												{template.status === DRAFT_STATUS
													? 'Publish'
													: 'Unpublish'}
											</StatusButton>
										)}
									</div>
								)}

								<FormControlLabel
									control={
										<Switch
											className="mr-2"
											checked={!hidePreview}
											onChange={() =>
												setHidePreview((current) => !current)
											}
											color="primary"
										/>
									}
									label={`HTML Preview is ${!hidePreview ? 'on' : 'off'}`}
									labelPlacement="start"
								/>
							</FormControl>
						</div>
						<Card className="mt-1% pt-1% pr-5% flex w-full">
							<CardContent className="w-1/2">
								<FormControl sx={{ width: '100%', marginBottom: 3 }}>
									<FormControlLabel
										control={
											<Switch
												checked={!hidePreview}
												onChange={() =>
													setHidePreview((current) => !current)
												}
												color="primary"
											/>
										}
										label={`${!hidePreview ? 'Hide' : 'Show'} HTML Preview`}
									/>
								</FormControl>
							</CardContent>
						</Card>
						<Card className="pt-1% pb-5% pr-5% flex w-full">
							<CardContent
								className={`w-${!hidePreview ? '1/2' : 'full'}`}
							>
								<FormControl sx={{ width: '100%', marginBottom: 3 }}>
									<FormControlLabel
										control={
											<Switch
												checked={template.emailEnabled}
												onChange={(event) =>
													setTemplate((current) => ({
														...current,
														emailEnabled: event.target.checked,
													}))
												}
												color="primary"
											/>
										}
										label={`Email Notifications are ${template.emailEnabled ? 'on' : 'off'}`}
									/>
								</FormControl>
								<FormControl sx={{ width: '100%', marginBottom: 3 }}>
									<FormControlLabel
										control={
											<Switch
												checked={template.useDefaultHeaderAndFooter}
												onChange={(event) =>
													setTemplate((current) => ({
														...current,
														useDefaultHeaderAndFooter:
															event.target.checked,
													}))
												}
												color="primary"
											/>
										}
										label={`Use Default Header & Footer is ${template.useDefaultHeaderAndFooter ? 'on' : 'off'}`}
									/>
								</FormControl>
								<FormControl sx={{ width: '100%', marginBottom: 3 }}>
									<FormControlLabel
										control={
											<Switch
												disabled
												checked={template.textEnabled}
												onChange={(event) =>
													setTemplate((current) => ({
														...current,
														textEnabled: event.target.checked,
													}))
												}
												color="primary"
											/>
										}
										label={`SMS Notifications are ${template.textEnabled ? 'on' : 'off'} (requires SMS integration)`}
									/>
								</FormControl>
								{/*<FormControl sx={{ width: "100%", marginBottom: 3 }}>*/}
								{/*	<FormControlLabel*/}
								{/*		control={*/}
								{/*			<Switch*/}
								{/*				checked={template.pushNotificationEnabled}*/}
								{/*				onChange={event => setTemplate(current => ({ ...current, pushNotificationEnabled: event.target.checked }))}*/}
								{/*				color="primary"*/}
								{/*			/>*/}
								{/*		}*/}
								{/*		label="Send Push Notifications"*/}
								{/*	/>*/}
								{/*</FormControl>*/}
								<FormControl sx={{ width: '100%', marginBottom: 3 }}>
									<TextField
										placeholder="Name *"
										value={template.name}
										label="Name"
										onChange={(e) =>
											setTemplate((current) => ({
												...current,
												name: e.target.value,
											}))
										}
										variant="standard"
										className="w-full sm:px-12"
										required
										InputProps={{
											readOnly: !!template.id,
										}}
									/>
								</FormControl>
								<FormControl sx={{ width: '100%', marginBottom: 3 }}>
									<TextField
										placeholder="Subject *"
										value={template.subject}
										label="Subject"
										onChange={(e) =>
											setTemplate((current) => ({
												...current,
												subject: e.target.value,
											}))
										}
										variant="standard"
										className="w-full sm:px-12"
										required
									/>
								</FormControl>
								<FormControl sx={{ width: '100%', marginBottom: 3 }}>
									<TextField
										placeholder="Description"
										value={template.description}
										label="Description"
										onChange={(e) =>
											setTemplate((current) => ({
												...current,
												description: e.target.value,
											}))
										}
										variant="standard"
										className="w-full sm:px-12"
										multiline
										rows={4}
									/>
								</FormControl>
								{!template.isDefault &&
									template.versions.length > 1 &&
									selectedVersion && (
										<>
											<div className="mb-3 md:flex md:flex-row md:items-center md:justify-between">
												<div className="md:flex md:flex-row md:items-center md:justify-between md:w-3/4">
													<VersionSelector
														value={selectedVersion?.id}
														onChange={handleVersionChange}
														versions={template?.versions}
													/>
													{!selectedVersion.isActive && (
														<FormControl className="mb-3 md:w-1/4">
															<FormControlLabel
																control={
																	<Switch
																		className="mr-2"
																		checked={selectedVersionActive}
																		onChange={() =>
																			setSelectedVersionActive(
																				(current) => !current
																			)
																		}
																		color="primary"
																	/>
																}
																label="Activate"
																labelPlacement="start"
															/>
														</FormControl>
													)}
												</div>
												<div className="mt-3 md:ml-3">
													<Button
														variant="outlined"
														color="primary"
														onClick={handleViewVersionsClick}
													>
														View Versions
													</Button>
												</div>
											</div>
											{currentActiveVersion && (
												<ActiveVersionAlert
													status={
														currentActiveVersion?.id ===
														selectedVersion?.id
															? 'active'
															: 'inactive'
													}
												/>
											)}
										</>
									)}

								<ModalVersionList
									open={versionsModalOpen}
									versions={template.versions}
									onItemClick={handleVersionClick}
									onDeleteClick={handleVersionDelete}
									onDialogClose={handleViewVersionsClose}
								/>

								<FormControl sx={{ width: '100%', marginBottom: 3 }}>
									{template && (
										<>
											<FormLabel>HTML Document Body</FormLabel>
											<CodeEditor
												className="w-full sm:px-1"
												height="60vh"
												defaultLanguage="handlebars"
												value={template.htmlBody}
												loading={fetching}
												onChange={(value) =>
													setTemplate((current) => ({
														...current,
														htmlBody: value,
													}))
												}
											/>
										</>
									)}
								</FormControl>
								<FormControl sx={{ width: '100%', marginBottom: 3 }}>
									<TextField
										placeholder="This text will display if the user is unable to accept HTML emails"
										value={template.plainBody}
										label="Plain Text Email Body"
										onChange={(e) =>
											setTemplate((current) => ({
												...current,
												plainBody: e.target.value,
											}))
										}
										variant="standard"
										className="w-full sm:px-12"
										multiline
										style={{ backgroundColor: '#efefef', padding: 5 }}
										rows={12}
									/>
								</FormControl>
								{/*<FormControl sx={{ width: "100%", marginBottom: 3 }}>*/}
								{/*	<TextField*/}
								{/*		placeholder="This text will be sent a text message"*/}
								{/*		value={template.textBody}*/}
								{/*		label="Text Message Body"*/}
								{/*		onChange={e => setTemplate(current => ({ ...current, textBody: e.target.value }))}*/}
								{/*		variant="standard"*/}
								{/*		className="w-full sm:px-12"*/}
								{/*		multiline*/}
								{/*		rows={12}*/}
								{/*	/>*/}
								{/*</FormControl>*/}
								{/*<FormControl sx={{ width: "100%", marginBottom: 3 }}>*/}
								{/*	<TextField*/}
								{/*		placeholder="This text will sent as a push notification to the user's device"*/}
								{/*		value={template.pushNotificationBody}*/}
								{/*		label="Push Notification Body"*/}
								{/*		onChange={e => setTemplate(current => ({ ...current, pushNotificationBody: e.target.value }))}*/}
								{/*		variant="standard"*/}
								{/*		className="w-full sm:px-12"*/}
								{/*		multiline*/}
								{/*		rows={12}*/}
								{/*	/>*/}
								{/*</FormControl>*/}
							</CardContent>
							{!hidePreview && (
								<CardContent className="w-1/2 border">
									<HandlebarsPreview
										className="w-full h-full min-h-200"
										template={template.htmlBody}
										data={previewData}
									/>
								</CardContent>
							)}
						</Card>
					</TabPanel>
					<TabPanel value={tab} index={1} dir={themeMUI.direction}>
						<Card className="pt-1% pb-5% px-5%">
							<Autocomplete
								disablePortal
								id="loan-officer-autocomplete"
								value={loanOfficer}
								options={loanOfficers.sort(
									(a, b) => -b.firstName.localeCompare(a.firstName)
								)}
								onChange={(event, value) =>
									handleLoanOfficerSelect(event, value)
								}
								renderOption={(props, option) => {
									return (
										<li {...props} key={option.id}>
											{option.label}
										</li>
									)
								}}
								renderInput={(params) => (
									<TextField {...params} label="Loan Officer" />
								)}
							/>
							{!!selectedSiteConfig.id ? (
								<HandlebarsPreview
									className="w-full h-full min-h-200"
									template={template.htmlBody}
									data={previewData}
								/>
							) : (
								<Typography className="pt-5 text-center" variant="h5">
									Select a site to preview
								</Typography>
							)}
						</Card>
					</TabPanel>
					<TabPanel value={tab} index={2} dir={themeMUI.direction}>
						<Card className="py-1% pb-5% px-5%">
							<CardContent>
								<div className="flex flex-row-reverse justify-start mb-10 items-center">
									<Button
										type="submit"
										variant="contained"
										color="primary"
										disabled={!loanOfficer}
										onClick={sendTest}
									>
										Send Test Notification
									</Button>
								</div>
								<Autocomplete
									disablePortal
									id="combo-box-demo"
									value={loanOfficer}
									options={loanOfficers.sort(
										(a, b) => -b.firstName.localeCompare(a.firstName)
									)}
									onChange={(event, value) =>
										handleLoanOfficerSelect(event, value)
									}
									renderOption={(props, option) => {
										return (
											<li {...props} key={option.id}>
												{option.label}
											</li>
										)
									}}
									renderInput={(params) => (
										<TextField {...params} label="Send To" />
									)}
								/>
							</CardContent>
						</Card>
					</TabPanel>
				</SwipeableViews>
			</div>
		</Page>
	)
}
