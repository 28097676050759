import React from 'react'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import { getAccount, updateAccount } from '../../services/client'
import { getErrorMessage } from '../../services/helper'
import { useAlert } from '../../hooks'
import { Loading } from '../Loading'
import { useAppContextActions } from '../AppContext/AppHooks'
import { useAppContext } from '../AppContext'
import AccountForm from '../../forms/AccountForm'
import queryKeys from '../../services/queryKeys'
import useUser from '../../hooks/useUser'
import { useMixpanel } from '../../hooks/useMixpanel'
import { eventTypes } from '../../services/constants'
const AccountSettings = () => {
	const { alert } = useAlert()
	const mixpanel = useMixpanel()
	const { applySiteConfig } = useAppContextActions()
	const { state } = useAppContext()
	const { siteConfig, socialLinks, legalLinks } = state
	const { user, setUser } = useUser()

	const queryClient = useQueryClient()
	const {
		isPending,
		error,
		data: account,
	} = useQuery({
		queryKey: [queryKeys.account],
		queryFn: getAccount,
	})

	if (error) {
		alert(getErrorMessage(error), { severity: 'error' })
	}

	const accountMutation = useMutation({
		mutationFn: updateAccount,
	})

	const save = async (data) => {
		try {
			const { mfaPreference } = data
			await accountMutation.mutateAsync(data)
			mixpanel.trackEvent(eventTypes.ACCOUNT_MFA_PREFERENCE_CHANGED, {
				mfaPreference,
			})
			alert('Account settings updated', { severity: 'success' })
			await queryClient.invalidateQueries({
				queryKey: [queryKeys.account],
			})
			if (mfaPreference === 'Required' && !user.mfaEnabled) {
				// Add skip MFA so the header still displays if current user doesn't have MFA enabled.
				// See comments in HeaderNavigation above showNavigation for more info
				setUser({ ...user, skippedMFAConfig: true })
			}
			siteConfig.mfaPreference = mfaPreference
			applySiteConfig({ siteConfig, socialLinks, legalLinks })
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	if (isPending || !account) {
		return <Loading size="small" />
	}

	return (
		<div className="w-full md:w-1/2 md:mb-0 md:max-w-screen-sm">
			<AccountForm account={account} onSubmit={save} />
		</div>
	)
}

export default AccountSettings
