import React, { useMemo } from 'react'
import { TextField as MuiTextField } from '@mui/material'
import { Controller } from 'react-hook-form'
import useFormField from '../../hooks/useFormField'
import IconButton from '@mui/material/IconButton'
import CancelIcon from '@mui/icons-material/Cancel'
import { merge } from 'lodash'

const TextField = ({
	variant = 'standard',
	placeholder,
	label,
	control,
	required,
	name,
	errors,
	onChange,
	onClear,
	value,
	InputProps,
	...rest
}) => {
	const { getError } = useFormField()
	const error = getError(name, errors)
	const placeholderTxt = useMemo(
		() => placeholder || label,
		[placeholder, label]
	)
	const labelTxt = useMemo(
		() => (required && label ? `${label} *` : label),
		[required, label]
	)

	const clearEndAdornment = (
		<div className="pr-2">
			<IconButton edge="end" onClick={onClear} size="small">
				<CancelIcon fontSize="small" />
			</IconButton>
		</div>
	)

	const inputProps = useMemo(() => {
		return onClear && value
			? merge({ endAdornment: clearEndAdornment }, InputProps)
			: InputProps
	}, [InputProps, clearEndAdornment, onClear])

	if (control) {
		return (
			<Controller
				name={name}
				control={control}
				render={({ field }) => (
					<MuiTextField
						variant={variant}
						label={labelTxt}
						placeholder={placeholderTxt}
						error={!!error}
						helperText={error ? error.message : undefined}
						{...field}
						onChange={(e) => {
							if (onChange) {
								onChange(e)
							}
							field.onChange(e)
						}}
						InputProps={inputProps}
						{...rest}
					/>
				)}
			/>
		)
	}

	return (
		<MuiTextField
			name={name}
			variant={variant}
			label={labelTxt}
			placeholder={placeholderTxt}
			error={!!error}
			helperText={error ? error.message : undefined}
			onChange={onChange}
			value={value}
			InputProps={inputProps}
			{...rest}
		/>
	)
}

export default TextField
