import React, { useEffect, useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useAppContext } from '../AppContext'
import {
	getBranches,
	getCorporations,
	Roles,
} from '../../services/client'
import { getTheme } from '../../config'
import { useWindowSize } from '../../hooks/useWindowSize'
import { getEntityName } from '../../services/helper'
import { Tooltip } from '@mui/material'

const theme = getTheme()

export const TableFilters = ({
	entity = '',
	handleFilterByCorporations,
	handleFilterByBranches,
	handleFilterByRoles,
	filterDeleted,
	setFilterDeleted,
}) => {
	const { state } = useAppContext()
	const { user } = state

	const [filterCorporation, setFilterCorporation] = useState('')
	const [filterBranch, setFilterBranch] = useState('')
	const [filterRole, setFilterRole] = useState('')
	const [data, setData] = useState([])
	const [corporations, setCorporations] = useState([])
	const [width] = useWindowSize()

	useEffect(() => {
		if (user.role === Roles.admin) {
			if (entity === 'Branch') {
				getCorporations().then((res) =>
					setData(res.sort((a, b) => (a.name > b.name ? 1 : -1)))
				)
			}

			if (entity === 'LoanOfficer') {
				getBranches().then((res) =>
					setData(res.sort((a, b) => (a.name > b.name ? 1 : -1)))
				)
				getCorporations().then((res) =>
					setCorporations(
						res.sort((a, b) => (a.name > b.name ? 1 : -1))
					)
				)
			}

			if (entity === 'User') {
				setData(theme.roles)
			}
		}
	}, [])

	const entityName = getEntityName(entity, true)

	const onChangeFilter = (e) => {
		const value = e.target.value

		switch (entity) {
			case 'Branch':
				setFilterCorporation(value)
				handleFilterByCorporations(value)
				break
			case 'LoanOfficer':
				setFilterBranch(value)
				handleFilterByBranches(value)
				break
			case 'User':
				setFilterRole(value)
				handleFilterByRoles(value)
				break
			default:
				return
		}
	}

	const onChangeCorporationFilter = (e) => {
		const value = e.target.value
		setFilterCorporation(value)
		handleFilterByCorporations(value)
	}

	return (
		<div className="flex flex-col md:flex-row">
			{entity === 'Branch' && (
				<FormControl
					sx={
						width > 767
							? { width: 260, mr: 2 }
							: { width: 280, mb: 3, mr: 2 }
					}
				>
					<Tooltip title={`Filter by Brand`}>
						<Select
							value={filterCorporation}
							displayEmpty
							onChange={(itemValue) => onChangeFilter(itemValue)}
						>
							<MenuItem value="" key="none">
								- All Brands -
							</MenuItem>
							{data.map((corporation, index) => (
								<MenuItem value={corporation.id} key={index}>
									{corporation.name}
								</MenuItem>
							))}
						</Select>
					</Tooltip>
				</FormControl>
			)}

			{entity === 'LoanOfficer' && (
				<FormControl
					sx={
						width > 767
							? { width: 260, mr: 2 }
							: { width: 280, mb: 3, mr: 2 }
					}
				>
					<Select
						value={filterCorporation}
						displayEmpty
						onChange={(itemValue) =>
							onChangeCorporationFilter(itemValue)
						}
					>
						<MenuItem value="" key="none">
							- All Brands -
						</MenuItem>
						{corporations.map((corporation, index) => (
							<MenuItem value={corporation.id} key={index}>
								{corporation.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}

			{entity === 'LoanOfficer' && (
				<FormControl
					sx={
						width > 767
							? { width: 260, mr: 2 }
							: { width: 280, mb: 3, mr: 2 }
					}
				>
					<Select
						value={filterBranch}
						displayEmpty
						onChange={(itemValue) => onChangeFilter(itemValue)}
					>
						<MenuItem value="" key="none">
							- All Branches -
						</MenuItem>
						{data.map((branch, index) => (
							<MenuItem value={branch.id} key={index}>
								{branch.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}

			{entity === 'User' && (
				<FormControl
					sx={
						width > 767
							? { width: 260, mr: 2 }
							: { width: 280, mb: 3, mr: 2 }
					}
				>
					<Select
						value={filterRole}
						displayEmpty
						onChange={(itemValue) => onChangeFilter(itemValue)}
					>
						<MenuItem value="" key="none">
							- All Roles -
						</MenuItem>
						{data.map((role, index) => (
							<MenuItem value={role} key={index}>
								{role}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}

			{[
				'Corporate',
				'Branch',
				'LoanOfficer',
				'Partner',
				'User',
				'DocumentTemplate',
				'Rule',
			].includes(entity) && (
				<FormControl
					sx={width > 767 ? { width: 260 } : { width: 280, mb: 3 }}
				>
					<Tooltip title={`Filter by Active/Deleted`}>
						<Select
							value={filterDeleted}
							displayEmpty
							onChange={(e) => setFilterDeleted(e.target.value)}
						>
							<MenuItem value="">
								Show{filterDeleted === '' && 'ing'} Active{' '}
								{entityName}
							</MenuItem>
							<MenuItem value="deleted">
								Show{filterDeleted === 'deleted' && 'ing'} Deleted{' '}
								{entityName} Only
							</MenuItem>
							<MenuItem value="all">
								Show{filterDeleted === 'all' && 'ing'} All{' '}
								{entityName}
							</MenuItem>
						</Select>
					</Tooltip>
				</FormControl>
			)}
		</div>
	)
}
