import React from 'react'
import { Select } from '../../../../components/formControls'
import useTask from '../hooks/useTask'
import useUser from '../../../../hooks/useUser'

const TaskSearchFilters = ({ borrowers, onChange }) => {
	const STATUS_OUTSTANDING = 'Outstanding'
	const STATUS_PENDING = 'Pending'
	const STATUS_COMPLETED = 'Completed'
	const ALL_PLACEHOLDER = 'All'

	const { getStatusName } = useTask()
	const { isLoanAdmin } = useUser()

	const [selectedStatus, setSelectedStatus] = React.useState('')
	const [selectedBorrower, setSelectedBorrower] = React.useState('')

	const statusOptions = [
		{
			value: STATUS_OUTSTANDING,
			label: getStatusName(STATUS_OUTSTANDING),
		},
		{ value: STATUS_PENDING, label: getStatusName(STATUS_PENDING) },
		{
			value: STATUS_COMPLETED,
			label: getStatusName(STATUS_COMPLETED),
		},
	]

	const handleStatusChange = (e) => {
		setSelectedStatus(e.target.value)
		onChange({
			status: e.target.value,
			borrowerId: selectedBorrower,
		})
	}

	const handleBorrowerChange = (e) => {
		setSelectedBorrower(e.target.value)
		onChange({
			status: selectedStatus,
			borrowerId: e.target.value,
		})
	}

	return (
		<div className={isLoanAdmin ? 'flex flex-row' : undefined}>
			<Select
				fullWidth
				label="Status"
				sx={{ width: 200 }}
				menuItems={statusOptions}
				placeholder={ALL_PLACEHOLDER}
				selectProps={{
					onChange: handleStatusChange,
				}}
				displayEmpty
			/>
			{isLoanAdmin && borrowers && (
				<div className="ms-4">
					<Select
						fullWidth
						label="Borrowers"
						sx={{ width: 200 }}
						menuItems={borrowers}
						placeholder={ALL_PLACEHOLDER}
						selectProps={{
							onChange: handleBorrowerChange,
						}}
						displayEmpty
					/>
				</div>
			)}
		</div>
	)
}

export default TaskSearchFilters
