import { useState, useMemo, useEffect } from 'react'
import DataTable from '../../../components/DataTable'
import { useAppContext } from '../../../components/AppContext'
import { Roles, getLoanApplications } from '../../../services/client'
import { applyMask } from '../../../services/utils'
import { formatDate } from '../../../services/helper'
import Page from '../../../components/Page'
import { navigationLinking } from '../../../services/navigation'
import { useAlert } from '../../../hooks'
import { useVenti } from 'venti'
import { getTheme } from '../../../config'
import { useNavigate } from 'react-router-dom'
import useUser from '../../../hooks/useUser'
import withAuth from '../../../components/withAuth'
import { useQuery } from '@tanstack/react-query'
import queryKeys from '../../../services/queryKeys'
import PipelineExpandedRow from './components/PipelineExpandedRow'

function Pipeline() {
	const { alert } = useAlert()
	const ventiState = useVenti()
	const theme = getTheme()
	const navigate = useNavigate()
	const { isBorrower, isRealtor, isLoanOfficer } = useUser()
	const { state } = useAppContext()
	const { user } = state
	const [filteredData, setFilteredData] = useState([])
	const [searchText, setSearchText] = useState('')

	const { isFetching, isRefetching, isError, data, refetch } =
		useQuery({
			queryKey: [queryKeys.loans, user.id],
			queryFn: getLoanApplications,
		})

	useEffect(() => {
		if (!isError) return
		alert('There was a problem loading the applications', {
			severity: 'error',
		})
	}, [isError])

	const columns = useMemo(() => {
		const roleBasedColumns = [
			{
				name: 'Loan Amount',
				selector: (row) => row.loanAmount,
				cell: (row) =>
					row.loanAmount
						? `$${applyMask(row.loanAmount, 'fullCurrency')}`
						: 'TBD',
				sortable: true,
			},
			{
				name: 'Name',
				selector: (row) =>
					`${row.borrowerFirstName} ${row.borrowerLastName}`,
				cell: (row) => (
					<div className="flex flex-col">
						<div>
							{row.borrowerFirstName} {row.borrowerLastName}
						</div>
						{row.coBorrowerFirstName && row.coBorrowerLastName && (
							<div className="mt-1">
								{row.coBorrowerFirstName} {row.coBorrowerLastName}
							</div>
						)}
					</div>
				),
				sortable: true,
			},
			{
				name: 'Address',
				selector: (row) => row.subjectPropertyAddress,
				cell: (row) => {
					const subjectPropertyAddress = []
					if (row.subjectPropertyStreet)
						subjectPropertyAddress.push(row.subjectPropertyStreet)
					if (row.subjectPropertyUnitNumber)
						subjectPropertyAddress.push(
							`${row.subjectPropertyUnitType} ${row.subjectPropertyUnitNumber}`
						)
					if (row.subjectPropertyCity)
						subjectPropertyAddress.push(
							`${row.subjectPropertyCity}, ${row.subjectPropertyState} ${row.subjectPropertyZip}`
						)

					if (subjectPropertyAddress.length > 0) {
						return (
							<div>
								{subjectPropertyAddress.map((line, index) => (
									<div key={index}>{line}</div>
								))}
							</div>
						)
					}

					return 'TBD'
				},
			},
			{
				name: 'Application Date',
				selector: (row) => formatDate(row.applicationDate, false),
				sortable: true,
			},
		]

		if (!isLoanOfficer) {
			roleBasedColumns.splice(2, 0, {
				name: 'Loan Officer',
				selector: (row) => row.loanOfficer || 'Not yet assigned',
			})
		}

		if (!isBorrower) {
			roleBasedColumns.unshift({
				name: 'Loan Number',
				selector: (row) => row.loanNumber,
				sortable: true,
			})
			roleBasedColumns.push({
				name: 'Status',
				selector: (row) => row.loanStatus,
				sortable: true,
			})
		}

		if (isRealtor) {
			roleBasedColumns.splice(4, 0, {
				name: 'Role',
				selector: (row) => {
					let role = 'Selling Agent'
					const userEmail = user.email.toLowerCase()
					const sellerAgentEmail =
						row.sellingAgent?.email?.toLowerCase()
					const buyerAgentEmail = row.buyerAgent?.email?.toLowerCase()

					if (
						buyerAgentEmail === userEmail &&
						sellerAgentEmail === userEmail
					) {
						role = 'Dual Agent'
					} else if (buyerAgentEmail === userEmail) {
						role = 'Buying Agent'
					}
					return role
				},
			})
		}

		return roleBasedColumns
	}, [user.role])

	useEffect(() => {
		const applicationData = data?.applications || []
		if (searchText !== '') {
			let newFilteredData = !searchText
				? applicationData
				: applicationData.filter(
						(d) =>
							d?.borrowerFirstName
								?.toLowerCase()
								.includes(searchText.toLowerCase()) ||
							d.borrowerLastName
								?.toLowerCase()
								.includes(searchText.toLowerCase()) ||
							`${d.borrowerFirstName} ${d.borrowerLastName}`
								.toLowerCase()
								.includes(searchText.toLowerCase()) ||
							d.loanOfficer
								?.toLowerCase()
								.includes(searchText.toLowerCase()) ||
							d.loanAmount
								?.toString()
								.includes(searchText.toLowerCase()) ||
							`${d.subjectPropertyCity} ${d.subjectPropertyState} ${d.subjectPropertyStreet}`
								.toLowerCase()
								.includes(searchText.toLowerCase()) ||
							d.loanNumber?.includes(searchText)
					)
			setFilteredData(newFilteredData)
		} else {
			setFilteredData(applicationData)
		}
	}, [data, searchText])

	const onRowClicked = async (row) => {
		await ventiState.unset(theme.storageKeys.loanData)
		await ventiState.unset(theme.storageKeys.loanTasks)
		await ventiState.unset(theme.storageKeys.loanDocs)
		await ventiState.set(theme.storageKeys.loanId, row.loanID)
		navigate(`/${navigationLinking.Portal}`)
	}

	const actionItems = [
		{
			name: [Roles.borrower].includes(user.role)
				? 'View Application'
				: [Roles.loanOfficer, Roles.branchManager].includes(user.role)
					? 'Impersonate'
					: 'Open Loan File',
			onClick: (e, row) => onRowClicked(row),
		},
	]

	const onSearchChange = (e) => {
		setSearchText(e.target.value)
	}
	const onClearSearchClick = () => {
		setSearchText('')
	}

	const pageTitle = isBorrower
		? 'My Applications'
		: 'Loan Applications'

	return (
		<Page page="app-pipeline" title={pageTitle} isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 h-full">
				<DataTable
					data={filteredData}
					columns={columns}
					defaultSortAsc={false}
					title={pageTitle}
					defaultSortFieldId="applicationDate"
					pagination={true}
					progressPending={isFetching || isRefetching}
					onRefreshClick={refetch}
					fixedHeader={true}
					actionItems={actionItems}
					expandableRowsComponent={PipelineExpandedRow}
					onSearchChange={onSearchChange}
					searchText={searchText}
					onClearSearchClick={onClearSearchClick}
					onRowClicked={onRowClicked}
				/>
			</div>
		</Page>
	)
}

export default withAuth(Pipeline)
