import React from 'react'
import { LoadingBtn } from '../components/Button'
import Select from '../components/formControls/Select'
import useForm from '../hooks/useForm'
import { TextField, Switch } from '../components/formControls'
import * as yup from 'yup'
import { useMixpanel } from '../hooks/useMixpanel'
import { eventTypes } from '../services/constants'

const AccountForm = ({ account, onSubmit }) => {
	const mixpanel = useMixpanel()
	const schema = yup.object().shape({
		allowedLoginsWithoutMFA: yup
			.number()
			.min(1, 'Must be greater than 1'),
	})

	const form = useForm({
		schema,
		values: account || {
			mfaPreference: 'Disabled',
			allowedLoginsWithoutMFA: 3,
		},
		resetOptions: {
			keepDirtyValues: true,
		},
	})

	const {
		handleSubmit,
		formState: { errors, isSubmitting },
		control,
		getValues,
		watch,
	} = form

	const submitForm = async () => {
		const data = getValues()
		await onSubmit(data)
	}

	const mfaPreference = watch('mfaPreference')

	const handleSwitchChange = (event) => {
		const { checked } = event.target
	}

	const handleSelectChange = (event) => {
		const { value } = event.target
		mixpanel.track(eventTypes.USER_PROFILE_MFA_TOGGLED, {
			preference: value,
		})
	}

	return (
		<form onSubmit={handleSubmit(submitForm)} noValidate>
			<div>
				<div className="mb-3">
					<Switch
						name="loanMilestoneNotificationsEnabled"
						label="Send Loan Milestone Notifications"
						control={control}
						errors={errors}
						onChange={handleSwitchChange}
					/>
				</div>
				<div>
					<Select
						name="mfaPreference"
						className="w-full"
						sx={{ mb: 2 }}
						label="MFA Preference"
						menuItems={[
							{ label: 'Disabled', value: 'Disabled' },
							{ label: 'Optional', value: 'Optional' },
							{ label: 'Required', value: 'Required' },
						]}
						control={control}
						errors={errors}
						onChange={handleSelectChange}
					/>
				</div>
				{mfaPreference === 'Required' && (
					<div className="mb-2">
						<TextField
							name="allowedLoginsWithoutMFA"
							label="Allowed logins for borrowers before enforcing MFA"
							className="w-full"
							control={control}
							errors={errors}
						/>
					</div>
				)}
			</div>
			<div className="mt-5">
				<LoadingBtn
					disabled={isSubmitting}
					loading={isSubmitting}
					type="submit"
					text="Save"
				/>
			</div>
		</form>
	)
}

export default AccountForm
