import React, { useEffect, useState } from 'react'
import { Divider, Link, Typography } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import Page from '../../components/Page'
import { ModalByPhone } from '../../components/modals/ModalByPhone'
import { navigationLinking } from '../../services/navigation'
import { getTheme } from '../../config'

const siteTheme = getTheme()

export default function HowToApplyView({ siteConfig, appLinks }) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const [hoveredId, setHoveredId] = useState(null)
	const [appLinksTitle, setAppLinksTitle] = useState('')
	const [modalByPhoneVisible, setModalByPhoneVisible] =
		useState(false)
	const navigate = useNavigate()

	useEffect(() => {
		if (siteConfig.entityType < 4)
			setAppLinksTitle(
				appLinks.length > 1
					? 'How would you like to apply?'
					: 'Apply Now'
			)
		else setAppLinksTitle('What would you like to do today?')
	}, [siteConfig])

	return (
		<div
			className="flex justify-center items-center bg-cover min-h-screen"
			style={
				!siteConfig?.backgroundImageUrl
					? { backgroundColor: siteConfig?.backgroundColor }
					: {
							backgroundImage: `url(${siteConfig?.backgroundImageUrl})`,
						}
			}
		>
			<img
				className="w-48 mb-5 lg:mb-0 lg:w-72 absolute top-5 right-5 hidden md:block"
				src={siteConfig?.portalLogoUrl || siteConfig?.logoUrl}
				alt="Logo"
			/>
			<ModalByPhone
				siteConfig={siteConfig}
				visible={modalByPhoneVisible}
				setVisible={setModalByPhoneVisible}
			/>
			<Page page="app-how-apply" noDarkMode>
				<div className="rounded-lg flex self-center content-center bg-white mx-1 my-5 dark:bg-slate-800">
					<div className="flex flex-col items-center justify-center w-full py-6 px-6 sm:pb-12 sm:pt-10 md:pt-12 sm:px-12">
						<img
							className="w-48 mb-5 block md:hidden"
							src={siteConfig?.portalLogoUrl || siteConfig?.logoUrl}
							alt="Logo"
						/>
						<p className="text-center text-lg sm:text-2xl font-bold mb-4 dark:text-white">
							{appLinksTitle}
						</p>
						<Divider
							className="hidden sm:block"
							sx={{
								width: 60,
								marginBottom: 3,
								backgroundColor:
									siteTheme.color.border.bottom_divider,
								height: 2,
								opacity: '0.7',
							}}
						/>
						<p className="text-center dark:text-white">
							Select an application method that best fits your
							situation.
						</p>

						<div className="w-full">
							{appLinks.map((item, index) => {
								if (
									siteConfig?.enabledServices[item.fieldName] !==
										false &&
									item.entityTypes.includes(siteConfig.entityType)
								)
									return (
										<div
											className="cursor-pointer flex items-center align-center m-3.5 p-4 border"
											key={index}
											onMouseOver={(e) =>
												setHoveredId(e.currentTarget.id)
											}
											onMouseOut={(e) => setHoveredId(null)}
											id={index}
											onClick={async () => {
												if (
													item.fieldName.toLowerCase() ===
													'ringcentral'
												) {
													if (fullScreen) setModalByPhoneVisible(true)
													else
														await window.open(
															`tel:${siteConfig.phone}`
														)
												} else {
													navigate(`/${item.link}`)
												}
											}}
										>
											{!!item.staticIcon && (
												<img
													className="h-12 sm:h-16 mr-2.5 my-1"
													alt={item.id}
													src={
														parseInt(hoveredId?.toString()) === index
															? item.animatedIcon
															: item.staticIcon
													}
												/>
											)}
											<Divider
												sx={{
													marginRight: 3,
													backgroundColor:
														siteTheme.color.border.bottom_divider,
												}}
												orientation="vertical"
												flexItem
											/>
											<div>
												<p
													style={{
														color: siteTheme.siteConfig.color.primary,
													}}
													className="sm:text-lg font-bold font-rubik"
												>
													{item.name}
												</p>
												<p
													style={{
														color:
															siteTheme.siteConfig.color.hover_black,
													}}
													className="text-sm: sm:text mt-1 text font-rubik"
												>
													{item.description}
												</p>
											</div>
										</div>
									)
							})}
						</div>

						<div className="mt-4">
							<Typography>
								<span className="dark:text-white">
									Already applied? Click here to&nbsp;
								</span>
								<Link
									className="text-blue-500 cursor-pointer"
									onClick={() => {
										navigate(`/${navigationLinking.SignIn}`)
									}}
									rel="noopener noreferrer"
									underline="hover"
									style={{
										color: siteTheme.siteConfig.color.primary,
										marginTop: 16,
									}}
								>
									<span>sign in</span>
								</Link>
							</Typography>
						</div>
					</div>
				</div>
			</Page>
		</div>
	)
}
