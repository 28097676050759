import React, { useEffect } from 'react'
import { List, Step, StepLabel, Stepper } from '@mui/material'
import TaskCheckboxForm from '../forms/TaskCheckboxForm'
import Box from '@mui/material/Box'
import { LoadingBtn, Button } from '../../../../components/Button'
import TaskListItem from './TaskListItem'
import TaskForm from '../../../../forms/TaskForm'
import { omit } from 'lodash'
import { TextField } from '../../../../components/formControls'
import useSearchText from '../../../../hooks/useSearchText'
import { Loading } from '../../../../components/Loading'
import { LoadingDots } from '../../../../components/LoadingDots'
import * as yup from 'yup'

const TaskImportStepper = ({
	tasks,
	onClose = undefined,
	onSubmit,
	searchText,
	onSearchChange,
	onSearchClear,
	borrowers,
	loading = false,
	loanId,
}) => {
	const tasksSchema = yup.object().shape({
		name: yup.string().required('Name is required'),
		type: yup.string().required('Type is required'),
		description: yup.string().required('Description is required'),
		daysDueFromApplication: yup
			.number()
			.required('Task Duration is required'),
		targetUserRole:
			borrowers.length > 0
				? yup.string().nullable()
				: yup.string().required('User role is required'),
		borrowers: yup.array().when('targetUserRole', {
			is: null,
			then: yup
				.array()
				.min(1)
				.required('At least borrower is required'),
		}),
	})

	const [activeStep, setActiveStep] = React.useState(0)
	const [selectedTasks, setSelectedTasks] = React.useState([])
	const [selectedTask, setSelectedTask] = React.useState(null)
	const [validationErrors, setValidationErrors] = React.useState([])

	const handleSelectTasksSubmit = (data) => {
		setActiveStep(1)
		setSelectedTasks(data)
	}

	const handleBackClick = () => {
		setActiveStep(activeStep - 1)
	}

	const handleEditTaskClick = (e, task) => {
		setSelectedTask(task)
	}

	const handleDeleteTaskClick = (e, task) => {
		setSelectedTasks(selectedTasks.filter((t) => t.id !== task.id))
	}

	const handleEditTaskCancel = () => {
		setSelectedTask(null)
	}

	const handleEditTaskSubmit = (data) => {
		const updatedTasks = selectedTasks.filter(
			(t) =>
				t.id !== selectedTask.id || t.losId !== selectedTask.losId
		)
		updatedTasks.push(data)
		setSelectedTasks(updatedTasks)
		setSelectedTask(null)
	}

	const handleSubmit = () => {
		onSubmit(selectedTasks)
	}

	useEffect(() => {
		const errors = []
		selectedTasks.forEach((task, i) => {
			try {
				tasksSchema.validateSync(task, {
					abortEarly: false,
				})
			} catch (e) {
				errors.push({ index: i, errors: e.errors })
			}
		})
		setValidationErrors(errors)
	}, [selectedTasks])

	return (
		<Box className="w-full">
			<Stepper activeStep={activeStep} alternativeLabel>
				<Step key={0} className="w-full">
					<StepLabel>Select tasks</StepLabel>
				</Step>
				<Step key={1}>
					<StepLabel>Customize tasks</StepLabel>
				</Step>
			</Stepper>
			{activeStep === 0 && (
				<>
					<div className="mb-3 flex flex-row items-center">
						<TextField
							label="Search"
							value={searchText}
							onChange={onSearchChange}
							onClear={onSearchClear}
							className="mr-2 w-1/2"
						/>
						{loading && <LoadingDots className="w-14" />}
					</div>
					{tasks.length === 0 && !loading && (
						<div>No tasks found</div>
					)}
					<TaskCheckboxForm
						tasks={tasks}
						onClose={onClose}
						onSubmit={handleSelectTasksSubmit}
						submitLabel="Next"
					/>
				</>
			)}
			{activeStep === 1 && (
				<>
					{selectedTask && (
						<TaskForm
							task={omit(selectedTask, [
								'id',
								'isGlobal',
								'createdAt',
								'updatedAt',
								'deletedAt',
								'checked',
							])}
							loanId={loanId}
							borrowers={borrowers}
							onSubmit={handleEditTaskSubmit}
							onCancel={handleEditTaskCancel}
						/>
					)}
					{!selectedTask && (
						<>
							<List>
								{selectedTasks.map((task, i) => (
									<TaskListItem
										key={task.id || task.losId}
										task={task}
										error={validationErrors.find(
											(e) => e.index === i
										)}
										onEditClick={handleEditTaskClick}
										onDeleteClick={handleDeleteTaskClick}
									/>
								))}
							</List>
							<div className="flex flex-row justify-between items-center">
								<div className="mr-2">
									<Button
										onClick={handleBackClick}
										text="Back"
										variant="outlined"
									/>
								</div>

								<LoadingBtn
									text="Submit"
									fullWidth={false}
									onClick={handleSubmit}
									disabled={validationErrors.length > 0}
									loading={loading}
								/>
							</div>
						</>
					)}
				</>
			)}
		</Box>
	)
}

export default TaskImportStepper
