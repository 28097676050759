import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faFacebookF,
	faXTwitter,
	faInstagram,
	faLinkedin,
} from '@fortawesome/free-brands-svg-icons'
import userAvatar from 'assets/images/landingPages/user-avatar.png'
import { getTitleByEntityType } from '../../services/utils'
import { imageExists } from '../../services/utils'

export const LoanOfficerLeftView = ({
	siteConfig = {},
	user = {},
}) => (
	<div className="flex flex-col items-center bg-slate-100 lg:rounded-3xl md:py-12 px-2 md:px-12 mb-12 dark:bg-slate-800 md:rounded-none">
		<div className="flex flex-col items-center w-full">
			<p className="text-center font-rubik my-5 md:mt-0 md:mb-6 text-2xl md:text-3xl">
				Your {getTitleByEntityType(siteConfig?.entityType)}
			</p>
			<div className="flex flex-col md:flex-row items-center">
				<img
					className="w-48 h-48 rounded-full mr-0 lg:mr-12 md:mr-12 mb-5 lg:mb-0 md:mb-0 object-cover"
					alt="Avatar"
					src={
						siteConfig?.profilePhotoUrl
							? siteConfig.profilePhotoUrl
							: userAvatar
					}
				/>
				<div className="flex items-center flex flex-col">
					<p className="w-full text-left font-rubik text-xl sm:text-2xl md:text-3xl ">
						{siteConfig?.name}
					</p>
					<div className="w-full flex justify-center flex-wrap mt-1 mb-1 flex-col">
						<div className="flex mr-0 md:mr-5 mb-2.5">
							<p className="font-rubik text-md">
								mobile:&nbsp;&nbsp;
							</p>
							<a
								target="_blank"
								href={`tel:${siteConfig?.phone}`}
								rel="noopener noreferrer"
								className="hover:underline"
							>
								<p className="text-slate-500 font-rubik font-light text-md dark:text-slate-400">
									{siteConfig?.phone}
								</p>
							</a>
						</div>
						{!!siteConfig?.fax && (
							<div className="flex mr-0 md:mr-5 mb-2.5">
								<p className="font-rubik text-md">fax:&nbsp;&nbsp;</p>
								<p className="text-slate-500 font-rubik font-light text-md dark:text-slate-400">
									{siteConfig.fax}
								</p>
							</div>
						)}
						{!!siteConfig?.tollFree && (
							<div className="flex mr-0 md:mr-5 mb-2.5">
								<p className="font-rubik text-md">
									office:&nbsp;&nbsp;
								</p>
								<a
									target="_blank"
									href={`tel:${siteConfig.tollFree}`}
									rel="noopener noreferrer"
									className="hover:underline"
								>
									<p className="text-slate-500 font-rubik font-light text-md dark:text-slate-400">
										{siteConfig.tollFree}
									</p>
								</a>
							</div>
						)}
						{!!siteConfig?.email && (
							<div className="flex mr-0 md:mr-5 mb-2.5  ">
								<p className="font-rubik text-md">
									email:&nbsp;&nbsp;
								</p>
								<a
									target="_blank"
									href={`mailto:${siteConfig.email}`}
									rel="noopener noreferrer"
									className="hover:underline"
								>
									<p className="text-slate-500 font-rubik font-light text-md break-all dark:text-slate-400">
										{siteConfig.email}
									</p>
								</a>
							</div>
						)}
						{!!siteConfig?.nmlsid && (
							<div className="flex mr-0 md:mr-5 mb-2.5  text-center">
								<p className="font-rubik text-md">
									NMLS #:&nbsp;&nbsp;
								</p>
								<p className="text-slate-500 font-rubik font-light text-md dark:text-slate-400">
									{siteConfig?.nmlsid}
								</p>
							</div>
						)}
					</div>
					<div className="flex justify-left flex-row w-full">
						{siteConfig?.facebookUrl && (
							<a
								className="flex 0 items-left w-8 h-8 mr-4"
								target="_blank"
								href={siteConfig.facebookUrl}
								rel="noopener noreferrer"
							>
								<FontAwesomeIcon icon={faFacebookF} />
							</a>
						)}
						{siteConfig?.instagramUrl && (
							<a
								className="flex 0 items-left w-8 h-8 mr-4"
								target="_blank"
								href={siteConfig.instagramUrl}
								rel="noopener noreferrer"
							>
								<FontAwesomeIcon icon={faInstagram} />
							</a>
						)}
						{siteConfig?.twitterUrl && (
							<a
								className="flex 0 items-left w-8 h-8 mr-4"
								target="_blank"
								href={siteConfig.twitterUrl}
								rel="noopener noreferrer"
							>
								<FontAwesomeIcon icon={faXTwitter} />
							</a>
						)}
						{siteConfig?.linkedInUrl && (
							<a
								className="flex 0 items-left w-8 h-8"
								target="_blank"
								href={siteConfig.linkedInUrl}
								rel="noopener noreferrer"
							>
								<FontAwesomeIcon icon={faLinkedin} />
							</a>
						)}
					</div>
				</div>
			</div>
		</div>
	</div>
)
