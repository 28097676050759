export default [
	{
		title: 'New Admin User',
		qId: 'UserRegistration',
		name: 'User Registration Group',
		type: 'group',
		target: '',
		items: [
			{
				question: 'Email Address',
				type: 'text',
				qId: 'email',
				target: 'FirstName',
				fieldId: 'email',
				placeholder: 'Enter Email',
				name: 'User Email',
				validation: 'email',
			},
			{
				question: 'Password',
				type: 'text',
				qId: 'password',
				fieldId: 'password',
				placeholder: 'Password',
				name: 'Password',
				secure: true,
				validation: 'password',
			},
			{
				question: 'Mobile Phone',
				type: 'text',
				qId: 'phone',
				fieldId: 'phone',
				placeholder: 'Enter Mobile Number',
				name: 'Phone',
				validation: 'phone',
			},
			{
				question: 'First Name',
				type: 'text',
				qId: 'firstName',
				target: 'LastName',
				fieldId: 'firstName',
				placeholder: 'Enter First Name',
				name: 'First Name',
				validation: 'alphanumeric',
			},
			{
				question: 'Last Name',
				type: 'text',
				qId: 'lastName',
				fieldId: 'lastName',
				placeholder: 'Enter Last Name',
				name: 'Last Name',
				validation: 'alphanumeric',
			},
		],
	},
]
