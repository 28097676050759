/**
 * @description Debug Token
 * @type {string}
 */
const debugToken =
	'eyJraWQiOiJ0TzlWcTRaWFRTUm9GSVp2emxQaHhjdlk5RGdudmVMXC9WcWxMcFBBaUdoQT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIxYjJjMDM1Yy1mYjhiLTRlMzItODllMC02OTBiNzY3MWU5NzkiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0yLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMl90S3ZjeDA5SEkiLCJjbGllbnRfaWQiOiI3ZnFxZzQyNDAzZWRoaGE3OWRuOG5vdGFnMyIsIm9yaWdpbl9qdGkiOiI4ZDU5YmI0My1kZTM5LTQ5MjgtODNhOS01ZWZjNGZhNDRhNzAiLCJldmVudF9pZCI6IjY2NTZkMmZmLWU0MWYtNGUzYi1iZDNjLTBmNjE4ZDI5OGJjMSIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE3MDcyMzE1MzksImV4cCI6MTcwNzIzNTEzOSwiaWF0IjoxNzA3MjMxNTM5LCJqdGkiOiI4ZTVkNTQ3Yy0xMTM1LTQ4YWEtOWI1NS1hZGU2OWRlMzVkNWIiLCJ1c2VybmFtZSI6IjFiMmMwMzVjLWZiOGItNGUzMi04OWUwLTY5MGI3NjcxZTk3OSJ9.qBUOsN16q35gCbcAAOT84JBc6f-kqDeSgyXJGQ6OKZn9nXaOLVuDD_NqGBM5hWPzKBjxKIYXN80Gq_Z24tDN6OJJPQnFO9256tdumTWtSzCUhhWIJno1HWTb9qwVkPgkBleg4CRvGB91wAMF34qzcgksvTFqCngtvSBGDqoO0bw5teZ9__FGMK0FIo-38FR-cI5umqUV2NvJf9sf9fwvtPwlKivrnjkiGaMbvj5m2B6yBh77kM-oeWT4Ohy2HgbSngrkyDxPwp_vv5dUn2xaRthgf7JKifk-zloz3NyAi1KWwb6hmBIV4dKj7Dxb8CaRPDz0P0nmRsi7wChG86xWaQ'

/**
 * @description Debug Loan ID
 * @type {string}
 */
const debugLoanId = '{c1f3b00f-fe18-4efe-8f32-c2e1c1feb748}'

/**
 * @description Debug Role
 * @type {string}
 */
// const debugRole = 'Realtor' // Realtor
// const debugRole = 'Borrower' // Borrower
// const debugRole = 'LoanOfficer' // Loan Officer
const debugRole = 'Admin' // Admin

/**
 * @description Debug Enabled
 * @type {boolean}
 */
const debugEnabled =
	(process.env.REACT_APP_DEBUG_ENABLED &&
		process.env.REACT_APP_DEBUG_ENABLED === 'true') ||
	false
// const debugEnabled = true

/**
 * @description Local host alias for running locally
 * @type {string}
 */
const localhostAlias =
	process.env.REACT_APP_LOCALHOST_ALIAS || 'matt.thebigpos.dev'
// const localhostAlias = "matt.thebigpos.dev"
// const localhostAlias = "joe.thebigpos.dev"
// const localhostAlias = "jagent.thebigpos.dev"
// const localhostAlias = "realtor.thebigpos.dev"
// const localhostAlias = 'matthewv.ahmcloans.com'
// const localhostAlias = 'joe.ahmcloans.com'

const theme = {
	maintenance: process.env.REACT_APP_POS_MAINTENANCE_MODE === 'true',
	debug: {
		enabled: debugEnabled,
		token: debugToken,
		loanId: debugLoanId,
		user: {
			accountID: '34039be4-eb3c-4091-8a8b-5be79736c830',
			role: debugRole,
			email: 'aaquino@thebigpos.com',
			firstName: 'Alex',
			lastName: 'Aquino',
			phone: '(555) 555-5555',
			loanIDs: [debugLoanId],
			loans: [
				{
					loanID: debugLoanId,
					loanNumber: '0003021286624',
					createdAt: '2022-11-10T21:04:25.151429Z',
				},
			],
		},
	},
	api: {
		// https://v2.absolute.thebigpos-api.com (Absolute)
		// https://shared.thebigpos-api.com (Shared)
		// https://v2.dev.thebigpos-api.com (V2 Dev)
		// https://staging.thebigpos-api.com (Staging)
		_localhostAlias: localhostAlias,
		host: {
			production:
				process.env.REACT_APP_POS_API_HOST ||
				'https://shared.thebigpos-api.com',
			dev:
				process.env.REACT_APP_POS_API_HOST_DEV ||
				'https://v2.dev.thebigpos-api.com',
			// 'https://shared.thebigpos-api.com',
			// 'https://v2.absolute.thebigpos-api.com',
		},
	},
	encryption: {
		passphrase: 'pU7zS5qP4qA8yG1zV2pT0vL8iB1eA8vL',
	},
	defaultFont: 'Rubik',
	secondaryFont: 'Montserrat',
	validation: {
		passwordLength: 8,
		passwordRegex:
			/^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/,
		passwordRequirements: [
			{
				key: 'length',
				label: 'Contains a minimum of 8 characters',
				regex: /^.{8,}$/,
			},
			{
				key: 'upper',
				label: 'Contains 1 Uppercase Letter (A-Z)',
				regex: /^(?=.*[A-Z]).+$/,
			},
			{
				key: 'lower',
				label: 'Contains 1 Lowercase Letter (a-z)',
				regex: /^(?=.*[a-z]).+$/,
			},
			{
				key: 'number',
				label: 'Contains 1 Number (0-9)',
				regex: /^(?=.*[0-9]).+$/,
			},
			{
				key: 'special',
				label: 'Contains 1 Special Character (!@#$&*)',
				regex: /^(?=.*[!@#$&*]).+$/,
			},
		],
		allowedDocumentTypes: [
			'application/pdf',
			'image/jpeg',
			'image/png',
		],
	},
	breakpoints: {
		dataTable: 768,
		mobile: 360,
	},
	pagination: {
		defaultPageSize: 20,
		defaultSortColumn: 'createdAt',
		defaultSortDirection: 'desc',
	},
	siteConfig: {
		color: {
			primary: '#000000',
			secondary: '#c3c3c3',
			text: '#303030',
			altText: '#FFFFFF',
			icon: '#000000',
		},
	},
	color: {
		primary: {
			black: '#303030',
			hover_black: '#74798C',
			grey: '#C7C9D1',
			blue: '#4A90E2',
			green: '#6fb265',
			hover_blue: '#E6F1FF',
			border_color: '#d0d0d0',
			white: '#FFFFFF',
			warning: '#FF0000',
			warning_pink: '#F06D82',
			cyan: '#4ABACC',
			active_blue: '#008bff',
			bottom_divider: '#C4CDD5',
			light_gray: '#D5D5D5',
			vlight_gray: '#EBEBEB',
			xlight_gray: '#EDEDED',
			dark_grey: '#858585',
			light_black: '#454F5B',
		},
		shadow: {
			vlight_gray: '#E0E0E0',
			dark_grey: '#141414',
		},
		secondary: {
			violet: '#33DBEE',
			hover_violet: '#6589C6',
			pink: '#F0569C',
		},
		background: {
			grey: '#F5F7FA',
			dark_grey: '#979797',
			soft_blue: '#819DF5',
			very_pale: '#F3F9FF',
			rates: '#e0edff',
			landing_rates: '#4a90e2',
			light_grayish_blue: '#E5E7EC',
			light_gray: '#D5D5D5',
			white: '#FFFFFF',
			warning: '#FF0000',
			blue: '#1E88E5',
			antiqueBlue: '#46637A',
		},
		border: {
			grey_blue: '#DDE7F2',
			soft_blue: '#819DF5',
			grayish_blue: '#BFC0C1',
			active_blue: '#4A90E2',
			bottom_divider: '#C4CDD5',
			light_gray: '#D5D5D5',
			vlight_gray: '#EBEBEB',
			blue: '#5099EE',
			cyan: '#4ACCBE',
			dark_grey: '#979797',
		},
		landing: {
			bg_color: '#eaf1fb',
			text: '#282d30',
		},
		notification: '#F06D82',
		disabled: '#a8aeaf',
	},
	search: {
		debounce: 500,
	},
	borderRadius: 8,
	button: {
		disabled: '#efefef',
		active_blue: '#008bff',
	},
	storageKeys: {
		account: '@tbp:account',
		user: '@tbp:user',
		siteConfig: '@tbp:siteConfig',
		authToken: '@tbp:token',
		authTokenExp: '@tbp:tokenExp',
		pleaseWaitMessage: '@tbp:pleaseWaitMessage',
		infoMessage: '@tbp:infoMessage',
		errorMessage: '@tbp:errorMessage',
		successMessage: '@tbp:successMessage',
		errorObject: '@tbp:errorObject',
		successObject: '@tbp:successObject',
		infoObject: '@tbp:successObject',
		loanDraft: '@tbp:loanDraft',
		loanId: '@tbp:loanId',
		loanData: '@tbp:loanData',
		loanTasks: '@tbp:loanTasks',
		loanDocs: '@tbp:loanDocs',
		document: '@tbp:document',
		tempPassword: '@tbp:maybe',
		redirectRoute: '@tbp:redirectRoute',
		editingLoanApplicationId: '@tbp:editingLoanApplicationId',
		editingLoanOfficerId: '@tbp:editingLoanOfficerId',
		editingLoanOfficerSiteConfigurationId:
			'@tbp:editingLoanOfficerSiteConfigurationId',
		editingBranchId: '@tbp:editingBranchId',
		editingBranchSiteConfigurationId:
			'@tbp:editingBranchSiteConfigurationId',
		editingCorporateId: '@tbp:editingCorporateId',
		editingCorporateSiteConfigurationId:
			'@tbp:editingCorporateSiteConfigurationId',
		editingAccountId: '@tbp:editingAccountId',
		editingWorkflowId: '@tbp:editingWorkflowId',
		viewingLoanApplicationId: '@tbp:editingLoanApplicationId',
		editingLeadId: '@tbp:editingLeadId',
		editingOpenHouseId: '@tbp:editingOpenHouseId',
		viewingRuleId: '@tbp:viewingRuleId',
		editingRuleId: '@tbp:editingRuleId',
		editingTaskId: '@tbp:editingTaskId',
		editingPartnerId: '@tbp:editingPartnerId',
		editingPartnerSiteConfigurationId:
			'@tbp:editingPartnerSiteConfigurationId',
		appSubmitted: '@tbp:appSubmitted',
		appPosting: '@tbp:appPosting',
		failoverModalVisible: '@tbp:failoverModalVisible',
		appFailed: '@tbp:appFailed',
		inviteCoBorrower: '@tbp:inviteCoBorrower',
		newUser: '@tbp:newUser',
		failedUser: '@tbp:failedUser',
		invite: '@tbp:invite',
		publicRates: '@tbp:publicRates',
		loanRates: '@tbp:loanRates',
		socialLinks: '@tbp:socialLinks',
		legalLinks: '@tbp:legalLinks',
		ssoToken: '@tbp:ssoToken',
		disclosurePackageInfo: '@tbp:disclosurePackageInfo',
		verificationRequestId: '@tbp:verificationRequestId',
		coBorrowerRoute: '@tbp:coBorrowerRoute',
		coBorrowerSubmitted: '@tbp:coBorrowerSubmitted',
		editingNotificationTemplateId:
			'@tbp:editingNotificationTemplateId',
		editingDocumentTemplateId: '@tbp:editingDocumentTemplateId',
		postLoanData: '@tbp:postLoanData',
		editingDeviceId: '@tbp:editingDeviceId',
		isFirstLoad: '@tbp:isFirstLoad',
		loanOfficer: '@tbp:loanOfficer',
	},
	support: {
		email: 'support@thebigpos.com',
		phone: '(833) 327-9767',
		website: 'support.thebigpos.com',
	},
	google: {
		apiKey: 'AIzaSyCeVODEpDP_qw8hMYiHS3834gfvZVTOcr4',
		region: 'us',
		language: 'en',
		places: {
			url: 'https://eas-cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api',
		},
	},
	modal: {
		leavePageText:
			'You have unsaved changes. Navigating away from this page will result in the loss of these changes. Do you wish to proceed?',
		overwriteText:
			'The record has been changed since it was loaded. Your changes may overwrite the previous update. Are you sure you want to save?',
		licensesText:
			'There are no licensed states selected. This site will not be able to accept applications. Do you wish to proceed?',
		leaveApplyPrequalifyPageText:
			"Are you sure you want to leave this page? You will lose all data you've entered.",
	},
	api_messages: {
		server_error:
			'There was an error processing the request. Please contact your administrator.',
	},
	roles: [
		'Borrower', // 0
		'LoanOfficer', // 1
		'Admin', // 2
		'Co-Borrower', // 3
		'Realtor', // 4
		"Loan Officer's Assistant", // 5
		'Closer', // 6,
		'Post Closer', // 7
		'Buyer Agent', // 8
		'Listing Agent', // 9
		'Settlement Agent', // 10
		'Escrow Agent', // 11
		'Title Company', // 12,
		'Buyers Attorney', // 13
		'Sellers Attorney', // 14
		'Appraisal Management Company', // 15
		'Insurance Agent', // 16
		'Branch Manager', // 17
	],
	taskTypes: [
		'Document',
		'Field',
		'E-Signature',
		'Disclosure',
		'Electronic Consent',
		'VOA',
		'VOI',
		'VOE',
		'VOC',
		'Payment',
		'VOIE',
		'IRS ID.ME',
	],
	taskButtonTitles: [
		'Upload',
		'Answer',
		'Sign',
		'Sign',
		'Give Consent',
		'Verify',
		'Verify',
		'Verify',
		'Verify',
		'Make Payment',
		'Verify',
		'Verify',
	],
	sideMenu: 'open',
	openDocFolders: '',
}

export const getTheme = () => theme

export const setThemeColorsAndImages = (siteConfig = {}) => {
	theme.siteConfig.color.primary =
		siteConfig.primaryColor || theme.siteConfig.color.primary
	theme.siteConfig.color.secondary =
		siteConfig.secondaryColor || theme.siteConfig.color.secondary
	theme.siteConfig.color.text =
		siteConfig.textColor || theme.siteConfig.color.text
	theme.siteConfig.color.icon =
		siteConfig.iconColor || theme.siteConfig.color.icon
	theme.siteConfig.color.background =
		siteConfig.backgroundColor || theme.siteConfig.color.background
	theme.siteConfig.backgroundImage =
		siteConfig.backgroundImageUrl || theme.siteConfig.backgroundImage
}

export const setFavicon = (icon) => {
	icon &&
		document
			.querySelectorAll('link[rel="icon"]')
			.forEach((item) => item.setAttribute('href', icon))
	icon &&
		document
			.querySelectorAll('link[rel="shortcut icon"]')
			.forEach((item) => item.setAttribute('href', icon))
}

export const setTitle = (title) => {
	if (title) document.title = title
}

const logging = {
	sentryDSN:
		process.env.REACT_APP_SENTRY_DSN ||
		'https://d6286e162ded29df79a965709a885a82@o4506717415145472.ingest.sentry.io/4506717420716032',
}

const exp = {
	getTheme,
	setTitle,
	setFavicon,
	theme,
	logging,
}

export default exp
