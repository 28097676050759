import React, { useMemo } from 'react'
import { formatDate } from '../../../../services/helper'
import { applyMask } from '../../../../services/utils'
import { getLeadBorrowerName } from '../../../../services/formattingUtils'
import RowExpandedProperty from '../../../../components/RowExpandedProperty'
import IconButton from '@mui/material/IconButton'
import DownloadIcon from '@mui/icons-material/Download'
import {
	getLoanDocumentContent,
	getLoanDocumentContentUrl,
} from '../../../../services/client'
import { Button } from '../../../../components/Button'
import { useVenti } from 'venti'
import { generatePath, useNavigate } from 'react-router-dom'
import { navigationLinking } from '../../../../services/navigation'
import { getTheme } from '../../../../config'
import WarningIcon from '@mui/icons-material/Warning'
import useUser from '../../../../hooks/useUser'
import { Tooltip } from '@mui/material'

const TaskRowExpanded = ({ data: loanTask }) => {
	const {
		status,
		value,
		loanID,
		documents,
		submittedBy,
		completedBy,
		task,
	} = loanTask
	const { description, type } = task
	const navigate = useNavigate()
	const { isLoanOfficer } = useUser()

	const verificationFolderMap = {
		VerificationOfAssets: 'Electronic Verification of Assets',
		VerificationOfIncome: 'Electronic Verification of Income',
		VerificationOfEmployment: 'Electronic Verification of Employment',
		VerificationOfIncomeAndEmployment:
			'Electronic Verification of Income and Employment',
	}

	const handleDownloadClick = async (document) => {
		return window.open(
			getLoanDocumentContentUrl({
				loanId: loanID,
				documentId: document.losDocumentID,
			})
		)
	}

	const handleVerificationTaskDocClick = () => {
		// @todo - add ability to view specific document. code in documents require base64 data & should just be able to pass the id or folder to the route
		const navigatePath = generatePath(
			`/${navigationLinking.Documents}`,
			{ loanId: loanTask.loanID }
		)
		navigate(navigatePath, {
			state: { folder: verificationFolderMap[loanTask.type] },
		})
	}

	return (
		<div className="p-5 dark:text-white">
			{description && (
				<RowExpandedProperty
					label="Description"
					value={description}
				/>
			)}
			{type === 'Field' && !value && (
				<RowExpandedProperty label="Response" value="Not answered" />
			)}

			{value && (
				<>
					<RowExpandedProperty label="Response" value={value} />
					{submittedBy && (
						<p className="text-gray-500 text-sm">
							Answered by {submittedBy.firstName}{' '}
							{submittedBy.lastName}
						</p>
					)}
				</>
			)}

			{type === 'Document' && documents.length === 0 && (
				<div>No documents uploaded</div>
			)}
			{documents.map((document) => {
				return (
					<div
						key={document.id}
						className="flex flex-row items-center"
					>
						{document.losStatus === 'Successful' && (
							<IconButton
								onClick={() => handleDownloadClick(document)}
							>
								<DownloadIcon />
							</IconButton>
						)}
						{(document.losStatus === 'Retrying' ||
							document.losStatus === 'Failed') &&
							isLoanOfficer && (
								<Tooltip
									title={
										document.losStatus === 'Retrying'
											? 'The document failed to upload to your LOS and will retry every 10 minutes for 24 hours. Please make sure the loan is not locked to avoid delays.'
											: 'The document failed to upload to your LOS. Please contact support.'
									}
								>
									<div className="ml-2 cursor-pointer">
										<WarningIcon color="error" />
									</div>
								</Tooltip>
							)}
						<div>{document.name || 'Untitled'}</div>
					</div>
				)
			})}
			{type.includes('Verification') && (
				<>
					{status === 'Outstanding' && <div>Not complete</div>}
					{documents.length === 0 && status !== 'Outstanding' && (
						<Button
							text="View Document"
							variant="outlined"
							onClick={handleVerificationTaskDocClick}
						/>
					)}
				</>
			)}

			{status === 'Completed' && completedBy && (
				<p className="text-gray-500 text-sm">
					Completed by {completedBy.firstName} {completedBy.lastName}
				</p>
			)}
		</div>
	)
}

export default TaskRowExpanded
