const queryKeys = {
	account: 'account',
	listings: 'listings',
	listing: 'listing',
	openHouseLeads: 'openHouseLeads',
	listingOffers: 'listingOffers',
	leads: 'leads',
	lead: 'lead',
	allowImpersonation: 'allowImpersonation',
	partners: 'partners',
	loanUsers: 'loanUsers',
	businessRules: 'businessRules',
	businessRule: 'businessRule',
	documentBuckets: 'documentBuckets',
	loanTasks: 'loanTasks',
	loanTasksDiff: 'loanTasksDiff',
	loanDocuments: 'loanDocuments',
	loanDocumentBuckets: 'loanDocumentBuckets',
	loan: 'loan',
	loans: 'loans',
	loanPreliminaryConditions: 'loanPreliminaryConditions',
	loanUnderwritingConditions: 'loanUnderwritingConditions',
	users: 'users',
	user: 'user',
	tasks: 'tasks',
	workflow: 'workflow',
	workflows: 'workflows',
}
export default queryKeys
