import React, { useEffect, useMemo, useState } from 'react'
import { useVenti } from 'venti'
import SwipeableViews from 'react-swipeable-views'
import { useTheme } from '@mui/material/styles'
import {
	Tabs,
	Tab,
	Card,
	CardContent,
	Button,
	FormControl,
	InputLabel,
	TextField,
	Select,
	MenuItem,
	Autocomplete,
	FormControlLabel,
	Typography,
	Switch,
} from '@mui/material'
import Page from '../../../components/Page'
import { useAppContext } from '../../../components/AppContext'
import { getTheme } from '../../../config'
import {
	getLoanOfficers,
	getDocumentTemplate,
	saveDocumentTemplate,
	generateDocumentFromTemplate,
	getSiteConfigurationByUrl,
	saveDocumentTemplateVersion,
	deleteDocumentTemplateVersion,
	getDocumentTemplates,
} from '../../../services/client'
import {
	a11yProps,
	getErrorMessage,
	documentTemplateTypes,
} from '../../../services/helper'
import { useAlert } from '../../../hooks'
import sampleLoanData from '../../../services/sampleLoanData.json'
import { replaceVars } from '../../../services/utils'
import { LoadingBtn } from '../../../components/Button'
import MailMergeSelect from '../../../components/MailMergeSelect'
import CodeEditor from '../../../components/CodeEditor'
import HandlebarsPreview from '../../../components/HandlebarsPreview'
import useHTMLTemplatePreview from '../../../hooks/useHTMLTemplatePreview'
import ModalNewVersion from '../../../components/modals/ModalNewVersion'
import ModalVersionList from '../../../components/modals/ModalVersionList'
import {
	generatePath,
	useNavigate,
	useParams,
} from 'react-router-dom'
import { cloneDeep } from 'lodash'
import { ModalYesNo } from '../../../components/modals/ModalYesNo'
import { navigationLinking } from '../../../services/navigation'
import { useMixpanel } from '../../../hooks/useMixpanel'
import { eventTypes } from '../../../services/constants'
import StatusButton from '../../../components/versions/StatusButton'
import StatusIcon from '../../../components/versions/StatusIcon'
import VersionSelector from '../../../components/versions/VersionSelector'
import ActiveVersionAlert from '../../../components/versions/ActiveVersionAlert'

const theme = getTheme()

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <div className="mt-5">{children}</div>}
		</div>
	)
}

export default function AdminDocumentTemplateEdit() {
	const DRAFT_STATUS = 'Draft'
	const PUBLISHED_STATUS = 'Published'

	const { alert } = useAlert()
	const { id } = useParams()
	const ventiState = useVenti()
	const { state } = useAppContext()
	const { siteConfig, user } = state
	const { getPreviewData } = useHTMLTemplatePreview()
	const navigate = useNavigate()
	const mixpanel = useMixpanel()

	const [tab, setTab] = useState(0)
	const [saving, setSaving] = useState(false)
	const [loanOfficer, setLoanOfficer] = useState(null)
	const [loanOfficers, setLoanOfficers] = useState([])
	const [loanId, setLoanId] = useState('')
	const [selectedSiteConfig, setSelectedSiteConfig] = useState({})
	const [preview, setPreview] = useState('')
	const [todaysDate, setTodaysDate] = useState('')
	const [hidePreview, setHidePreview] = useState(false)
	const [fetching, setFetching] = useState(true)
	const [activeTemplates, setActiveTemplates] = useState([])
	const [selectedVersion, setSelectedVersion] = useState(null)
	const [selectedVersionActive, setSelectedVersionActive] =
		useState(false)
	const [htmlBody, setHtmlBody] = useState('')
	const [newVersionModalOpen, setNewVersionModalOpen] =
		useState(false)
	const [versionsModalOpen, setVersionsModalOpen] = useState(false)
	const [statusModal, setStatusModal] = useState({
		open: false,
		text: '',
		isSave: false,
	})

	// const [restoreRecordModalVisible, setRestoreRecordModalVisible] = useState(false)
	// const [showDeleted, setShowDeleted] = useState(false)
	const themeMUI = useTheme()

	const [template, setTemplate] = useState({
		id: '',
		name: '',
		htmlBody: '',
		type: '',
		description: '',
		createdAt: new Date(),
		updatedAt: new Date(),
		versions: [],
		status: DRAFT_STATUS,
	})

	useEffect(() => {
		const now = new Date()
		setTodaysDate(
			`${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`
		)
	}, [])

	useEffect(() => {
		const fetchTemplates = async () => {
			const templates = await getDocumentTemplates()
			setActiveTemplates(templates)
		}

		fetchTemplates()
	}, [])

	const isTemplateOverridden = useMemo(() => {
		const overrideTemplate = activeTemplates?.filter(
			(activeTemplate) => activeTemplate?.type === template?.type
		)
		return (
			template?.isDefault &&
			!template?.multipleCustomTemplates &&
			overrideTemplate?.length > 1
		)
	}, [activeTemplates])

	useEffect(() => {
		if (id !== 'new') {
			setFetching(true)
			getDocumentTemplate(id)
				.then((data) => {
					setTemplate(data)
				})
				.catch((e) => {
					alert(getErrorMessage(e), { severity: 'error' })
					ventiState.set(
						theme.storageKeys.errorMessage,
						e?.data?.message || theme.api_messages.server_error
					)
				})
				.finally(() => {
					setFetching(false)
				})
		} else {
			setFetching(false)
		}

		getLoanOfficers().then((items) => {
			const loanOfficersData = []
			items.forEach((item) => {
				item?.siteConfigurations.forEach((site) => {
					loanOfficersData.push({
						...item,
						label: `${item.firstName} ${item.lastName}`,
						url: site.url,
						siteConfigurationId: site.id,
					})
				})
			})
			setLoanOfficers(loanOfficersData)
		})
	}, [id])

	const canSubmit = template?.name.length > 0 && htmlBody.length > 0

	const currentActiveVersion = useMemo(() => {
		return template?.versions.find((v) => v.isActive)
	}, [template])

	useEffect(() => {
		if (!selectedVersion) {
			setSelectedVersion(currentActiveVersion)
		}
	}, [template?.versions, selectedVersion, currentActiveVersion])

	const saveTemplate = async () => {
		setSaving(true)
		if (template.isDefault) {
			delete template.id
			delete template.isDefault
			template.accountID = user.accountID
		}

		try {
			if (
				template?.id &&
				currentActiveVersion?.id !== selectedVersion?.id
			) {
				await saveDocumentTemplateVersion({
					documentId: template.id,
					data: {
						...selectedVersion,
						htmlBody,
						isActive: selectedVersionActive,
					},
				})
			}
			// @todo add logic to check for changes in fields before sending this request
			const result = await saveDocumentTemplate({
				...template,
				htmlBody,
			})
			const eventType = template.id
				? eventTypes.DOCUMENT_TEMPLATE_UPDATED
				: eventTypes.DOCUMENT_TEMPLATE_CREATED
			mixpanel.trackEvent(eventType, {
				name,
				...(id && { id }),
			})

			const data = await getDocumentTemplate(result.id)
			setTemplate(data)
			const activeVersion = data.versions.find((v) => v.isActive)
			setSelectedVersion(activeVersion)
			alert(
				`Document Template ${template.name} successfully ${template.id ? 'updated' : 'created'}`
			)
			if (result.id !== template.id) {
				// if this is a new template or default override, redirect to the appropriate page
				navigate(
					generatePath(
						`/${navigationLinking.AdminDocumentTemplateEdit}`,
						{ id: result.id }
					)
				)
			}
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		} finally {
			setSaving(false)
		}
	}

	const handleLoanOfficerSelect = async (event, value) => {
		if (value)
			setSelectedSiteConfig(
				await getSiteConfigurationByUrl(
					value.siteConfigurations[0].url
				)
			)
		else setSelectedSiteConfig({})
		setLoanOfficer(value)
	}

	const generatePreview = () => {
		generateDocumentFromTemplate({
			templateID: id,
			loanID: loanId,
			siteConfigurationID: siteConfig.id,
			preview: false,
		})
			.then((response) => {
				setPreview(response)
			})
			.catch((e) => {
				alert(getErrorMessage(e), { severity: 'error' })
				ventiState.set(
					theme.storageKeys.errorMessage,
					e?.data?.message || theme.api_messages.server_error
				)
			})
	}

	const handleTabChange = (event, value) => {
		setTab(value)
	}

	const handleChangeIndex = (index) => {
		setTab(index)
	}

	const renderDocPreview = (doc) => {
		return (
			<img
				alt={doc.name}
				src={`data:application/${doc.extension};base64,${doc.base64Data}`}
				style={{ flex: 1 }}
			/>
		)
	}

	const handleLoanGUIDChange = (e) => {
		const val = e.target.value.replace(/[^a-f0-9-]/g, '')
		setLoanId(val)
	}

	const loanIdIsValid =
		/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(
			loanId
		)

	let saveButtonText = 'Create'
	if (template?.id) {
		if (template.isDefault) {
			saveButtonText = 'Override Default'
		} else {
			saveButtonText = 'Update'
		}
	}

	const previewData = useMemo(
		() => getPreviewData(template),
		[template]
	)

	useEffect(() => {
		setHtmlBody(selectedVersion?.htmlBody || '')
	}, [selectedVersion])

	const handleNewVersionClick = () => {
		setNewVersionModalOpen(true)
	}

	const handleNewVersionDialogClose = () => {
		setNewVersionModalOpen(false)
	}

	const handleNewVersionSubmit = async (data) => {
		try {
			setSaving(true)
			const version = await saveDocumentTemplateVersion({
				documentId: template.id,
				data: {
					htmlBody,
					...data,
				},
			})

			const updatedTemplate = cloneDeep(template)
			if (data.isActive) {
				const prevActiveVersionIndex =
					updatedTemplate.versions.findIndex((v) => v.isActive)
				updatedTemplate.versions[prevActiveVersionIndex].isActive =
					false
			}
			updatedTemplate.versions.unshift(version)
			setTemplate(updatedTemplate)
			setSelectedVersion(version)
			setSelectedVersionActive(false)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		} finally {
			setSaving(false)
			setNewVersionModalOpen(false)
		}
	}

	const handleViewVersionsClick = () => {
		setVersionsModalOpen(true)
	}

	const handleViewVersionsClose = () => {
		setVersionsModalOpen(false)
	}

	const closeStatusModal = () => {
		setStatusModal({
			open: false,
			text: '',
			isSave: false,
		})
	}

	const handleVersionClick = (version) => {
		setSelectedVersion(version)
		setVersionsModalOpen(false)
	}

	const handleVersionDelete = async (version) => {
		try {
			await deleteDocumentTemplateVersion({
				documentId: template.id,
				versionId: version.id,
			})
			setVersionsModalOpen(false)
			const updatedTemplate = cloneDeep(template)
			updatedTemplate.versions = updatedTemplate.versions.filter(
				(v) => v.id !== version.id
			)
			setTemplate(updatedTemplate)
			alert(`Version ${version.name} successfully deleted`)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		}
	}

	const handleVersionChange = (e) => {
		const version = template.versions.find(
			(v) => v.id === e.target.value
		)
		setSelectedVersion(version)
		setSelectedVersionActive(false)
	}

	const handleStatusClick = () => {
		const statusChangeText = `Are you sure you want to ${
			template.status === 'Draft' ? 'publish' : 'unpublish'
		} this document template?`

		setStatusModal({
			open: true,
			text: statusChangeText,
			isSave: false,
		})
	}
	const handleStatusConfirm = async () => {
		try {
			const requestData = {
				...template,
				status:
					template.status === DRAFT_STATUS
						? PUBLISHED_STATUS
						: DRAFT_STATUS,
			}
			await saveDocumentTemplate(requestData)
			setTemplate(requestData)
			closeStatusModal()
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		}
	}

	return (
		<Page isFullWidth={true} title="Edit Document Template">
			<ModalYesNo
				modalConfirmation={statusModal}
				modalConfirm={handleStatusConfirm}
				setModalConfirmation={setStatusModal}
			/>
			<ModalNewVersion
				open={newVersionModalOpen}
				onDialogClose={handleNewVersionDialogClose}
				onSubmit={handleNewVersionSubmit}
			/>
			<div className="pl-5 pr-5 pb-20 pt-5 h-screen overflow-auto">
				<p className="text-xl md:text-2xl font-rubik font-bold mr-4 dark:text-white">
					Document Templates -{' '}
					{id === 'new' ? 'Create New Template' : template.name}
				</p>
				<div className="flex flex-wrap justify-end my-5">
					{!template?.isDefault && id !== 'new' && (
						<div className="mr-3">
							<LoadingBtn
								disabled={!canSubmit || saving || 0 !== tab}
								text="Save as new version"
								onClick={handleNewVersionClick}
								fullWidth={false}
								variant="outlined"
							/>
						</div>
					)}
					<LoadingBtn
						id={`AdminCorporateEditSaveButton`}
						disabled={
							isTemplateOverridden ||
							!canSubmit ||
							saving ||
							0 !== tab
						}
						loading={saving}
						text={saveButtonText}
						onClick={saveTemplate}
						fullWidth={false}
					/>
				</div>
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					value={tab}
					onChange={handleTabChange}
					aria-label="Tabs"
				>
					<Tab label="Edit" {...a11yProps(0)} />
					<Tab label="Full Preview" {...a11yProps(1)} />
					<Tab label="Test" {...a11yProps(2)} />
				</Tabs>
				<SwipeableViews
					axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={tab}
					onChangeIndex={handleChangeIndex}
				>
					<TabPanel value={tab} index={0} dir={themeMUI.direction}>
						<div className="flex w-full">
							<div className="w-full">
								<MailMergeSelect combinedOptions className={'flex'} />
							</div>
							<FormControl className="w-1/3 flex">
								{!template.isDefault && (
									<div className="flex flex-row items-center justify-end my-2">
										<StatusIcon status={template.status} />
										{template.id && (
											<StatusButton
												status={template.status}
												onClick={handleStatusClick}
											>
												{template.status === DRAFT_STATUS
													? 'Publish'
													: 'Unpublish'}
											</StatusButton>
										)}
									</div>
								)}

								<FormControlLabel
									control={
										<Switch
											className="mr-2"
											checked={!hidePreview}
											onChange={() =>
												setHidePreview((current) => !current)
											}
											color="primary"
										/>
									}
									label={`HTML Preview is ${!hidePreview ? 'on' : 'off'}`}
									labelPlacement="start"
								/>
							</FormControl>
						</div>

						<Card className="pt-1% pb-5% flex w-full">
							<CardContent
								className={`w-${!hidePreview ? '1/2' : 'full'}`}
							>
								<FormControl sx={{ width: '100%', marginBottom: 3 }}>
									<InputLabel>Document Type</InputLabel>
									<Select
										variant="standard"
										value={template.type}
										onChange={(e) =>
											setTemplate((current) => ({
												...current,
												type: e.target.value,
											}))
										}
										label="Document Type"
										placeholder="Document Type"
									>
										<MenuItem value="" key="none">
											Select a Document Type
										</MenuItem>
										{documentTemplateTypes
											.filter((t) => t.active)
											.map((option, index) => (
												<MenuItem key={index} value={option.value}>
													{option.label}
												</MenuItem>
											))}
									</Select>
								</FormControl>
								<FormControl sx={{ width: '100%', marginBottom: 3 }}>
									<TextField
										placeholder="Name *"
										value={template.name}
										label="Template Name"
										onChange={(e) =>
											setTemplate((current) => ({
												...current,
												name: e.target.value,
											}))
										}
										variant="standard"
										className="w-full sm:px-12"
										required
									/>
								</FormControl>
								<FormControl sx={{ width: '100%', marginBottom: 3 }}>
									<TextField
										placeholder="Description"
										value={template.description}
										label="Description"
										onChange={(e) =>
											setTemplate((current) => ({
												...current,
												description: e.target.value,
											}))
										}
										variant="standard"
										className="w-full sm:px-12"
									/>
								</FormControl>
								{!template.isDefault &&
									template.versions.length > 1 &&
									selectedVersion && (
										<>
											<div className="mb-3 md:flex md:flex-row md:items-center md:justify-between">
												<div className="md:flex md:flex-row md:items-center md:justify-between md:w-3/4">
													<VersionSelector
														value={selectedVersion?.id}
														onChange={handleVersionChange}
														versions={template?.versions}
													/>
													{!selectedVersion.isActive && (
														<FormControl className="mb-3 md:w-1/4">
															<FormControlLabel
																control={
																	<Switch
																		className="mr-2"
																		checked={selectedVersionActive}
																		onChange={() =>
																			setSelectedVersionActive(
																				(current) => !current
																			)
																		}
																		color="primary"
																	/>
																}
																label="Activate"
																labelPlacement="start"
															/>
														</FormControl>
													)}
												</div>
												<div className="mt-3 md:ml-3">
													<Button
														variant="outlined"
														color="primary"
														onClick={handleViewVersionsClick}
													>
														View Versions
													</Button>
												</div>
											</div>
											{currentActiveVersion && (
												<ActiveVersionAlert
													status={
														currentActiveVersion?.id ===
														selectedVersion?.id
															? 'active'
															: 'inactive'
													}
												/>
											)}
										</>
									)}
								<ModalVersionList
									open={versionsModalOpen}
									versions={template.versions}
									onItemClick={handleVersionClick}
									onDeleteClick={handleVersionDelete}
									onDialogClose={handleViewVersionsClose}
								/>
								<FormControl sx={{ width: '100%', marginBottom: 3 }}>
									<CodeEditor
										className="w-full sm:px-1"
										height="60vh"
										defaultLanguage="handlebars"
										value={htmlBody}
										loading={fetching}
										onChange={(value) => setHtmlBody(value)}
									/>
								</FormControl>
							</CardContent>
							{!hidePreview && (
								<CardContent className="w-1/2 border">
									<HandlebarsPreview
										className="w-full h-full min-h-200"
										template={htmlBody}
										data={previewData}
									/>
								</CardContent>
							)}
						</Card>
					</TabPanel>

					<TabPanel value={tab} index={1} dir={themeMUI.direction}>
						<Card className="pt-1% pb-5% px-5%">
							<Autocomplete
								disablePortal
								id="loan-officer-autocomplete"
								value={loanOfficer}
								options={loanOfficers.sort(
									(a, b) => -b.firstName.localeCompare(a.firstName)
								)}
								onChange={(event, value) =>
									handleLoanOfficerSelect(event, value)
								}
								renderOption={(props, option) => {
									return (
										<li {...props} key={option.id}>
											{option.label}
										</li>
									)
								}}
								renderInput={(params) => (
									<TextField {...params} label="Loan Officer" />
								)}
							/>
							{!!selectedSiteConfig.id ? (
								<iframe
									className="w-full h-full min-h-200 pt-5"
									title="Template"
									srcDoc={replaceVars(template.htmlBody, {
										...selectedSiteConfig,
										...sampleLoanData,
										Date: todaysDate,
									})}
								/>
							) : (
								<Typography className="pt-5 text-center" variant="h5">
									Select a site to preview
								</Typography>
							)}
						</Card>
					</TabPanel>

					<TabPanel value={tab} index={2} dir={themeMUI.direction}>
						<Card className="py-1% pb-5% px-5%">
							<CardContent>
								<div className="flex flex-row-reverse justify-start mb-10 items-center">
									<Button
										type="submit"
										variant="contained"
										color="primary"
										disabled={!loanOfficer || !loanIdIsValid}
										onClick={generatePreview}
									>
										Generate PDF Preview
									</Button>
								</div>
								<FormControl sx={{ width: '100%', marginBottom: 3 }}>
									<Autocomplete
										includeInputInList
										disablePortal
										value={loanOfficer}
										options={loanOfficers.sort(
											(a, b) =>
												-b.firstName.localeCompare(a.firstName)
										)}
										onChange={(event, value) =>
											handleLoanOfficerSelect(event, value)
										}
										renderOption={(props, option) => {
											return (
												<li {...props} key={option.id}>
													{option.label}
												</li>
											)
										}}
										renderInput={(params) => (
											<TextField {...params} label="Loan Officer" />
										)}
									/>
								</FormControl>
								<FormControl sx={{ width: '100%', marginBottom: 3 }}>
									<TextField
										placeholder="Loan GUID"
										value={loanId}
										label="Loan GUID"
										onChange={handleLoanGUIDChange}
										error={loanId.length > 0 && !loanIdIsValid}
										variant="standard"
										className="w-full sm:px-12"
									/>
									{loanId.length > 0 && !loanIdIsValid && (
										<p className="text-sm text-red-500 mt-5">
											Enter a valid Loan GUID
										</p>
									)}
								</FormControl>
								{!!preview && renderDocPreview(preview)}
							</CardContent>
						</Card>
					</TabPanel>
				</SwipeableViews>
			</div>
		</Page>
	)
}
