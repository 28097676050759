import React, { useState } from 'react'
import { CardItem } from '../../components/CardItem'
import { useNavigate } from 'react-router-dom'
import { ModalByPhone } from '../../components/modals/ModalByPhone'
import { useAppContext } from '../../components/AppContext'

const AppLinkCards = ({
	appLinks,
	source = null,
	className = undefined,
}) => {
	const { state } = useAppContext()
	const navigate = useNavigate()
	const { siteConfig } = state

	const [modalByPhoneVisible, setModalByPhoneVisible] =
		useState(false)

	const handleAppLink = async (appLink) => {
		switch (appLink.fieldName) {
			case 'RingCentral':
				setModalByPhoneVisible(true)
				break
			case 'ringCentral':
				setModalByPhoneVisible(true)
				break
			case 'Alexa':
				await window.open(appLink.link)
				break
			default:
				navigate(`/${appLink.link}`, { state: appLink } || {})
		}
	}

	return (
		<div className={className}>
			<ModalByPhone
				siteConfig={siteConfig}
				visible={modalByPhoneVisible}
				setVisible={setModalByPhoneVisible}
			/>
			<div className="flex flex-wrap justify-center">
				{appLinks.map((app, index) => (
					<CardItem
						key={index}
						item={app}
						index={index}
						onClick={() => handleAppLink(app)}
						source={source}
					/>
				))}
			</div>
		</div>
	)
}

export default AppLinkCards
